import React, { Component } from "react";
import { AppContext } from "../config/common";
import Catalog from "../Catalog";
import { Navigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { StoreService } from "../services/store"; 
import FormCheckGroup from "../widgets/FormCheckGroup";
import { BookService } from "../services/book";
class StoreController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      storeItems: {
        data: [],
        pagination: {
          page: 1,
          pageSize: 25,
          pageCount: 1,
          total: 0,
        },
      },
      filterData: { 
        PropertyList1:[],
        PropertyList2:[],
        PropertyList3:[],
        Category:[],
        SubTitle:[],
      },
      authors:[],
      storeName: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if(this.state.store=="books"){
      this.fetchAuthors();
    }
    this.fetchStore();
  }
  componentDidUpdate() {
    if (this.state.store !== this.state.storeName) {
      this.fetchStore();
    }
  }

  componentWillUnmount() {}

  fetchAuthors=async ()=>{
    const authors=await BookService.fetchAuthors();
    this.setState({ authors }); 
  }

  fetchStore=async (filterData={})=> {
    const storeName = this.state.store;
    this.setState({ storeName }); 
    const storeItems = await StoreService.fetchStore(storeName,filterData);
    this.setState({ storeItems });
  }
  handleChange=(e)=>{
    const {name,value,checked} = e.target;
    let filterData = this.state.filterData; 
    if(checked){
      filterData[name]=[...filterData[name],value];
    }else{
      filterData[name]=filterData[name].filter((v,k)=>v!==value)
    }
    const storeItems ={
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };
    this.setState({ filterData ,storeItems })
    this.fetchStore(filterData);
  }
  render() {
    let catalog = Catalog.Book;
    switch (this.state.store) {
      case "books":
        catalog = Catalog.Book;
        break;
      case "lawyers":
        catalog = Catalog.Lawyer;
        break;
      case "firms":
        catalog = Catalog.Firm;
        break;

      default:
        return (
          <>
            <Navigate replace to="/404" />
          </>
        );
    }
    return (
      <>
        <div className="store-wrap">
          <div className="row">
            <div className="col-md-3">
              <Card>
                <Card.Header>
                  <span>Filters</span>
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    {this.state.store==="books"? ( <>
                      <div className="col-md-12"> 
                        <FormCheckGroup
                          title={catalog.Strings.profile.Category.title}
                          placeholder={
                            catalog.Strings.profile.Category.placeholder
                          }
                          value={this.state.filterData?.Category}
                          options={catalog.Config.Category}
                          name={"Category"}
                          handle={this.handleChange}
                        />
                      </div>

                    <div className="col-md-12"> 
                      <FormCheckGroup
                        title={catalog.Strings.profile.SubTitle.title}
                        placeholder={
                          catalog.Strings.profile.SubTitle.placeholder
                        }
                        value={this.state.filterData?.SubTitle}
                        options={this.state.authors}
                        name={"SubTitle"}
                        handle={this.handleChange}
                      />
                    </div>
                      </>
                    ) : (
                      <>

                        <div className="col-md-12"> 
                        <FormCheckGroup
                          title={catalog.Strings.profile.PropertyList3.title}
                          placeholder={
                            catalog.Strings.profile.PropertyList3.placeholder
                          }
                          value={this.state.filterData?.PropertyList3}
                          options={catalog.Config.PropertyList3}
                          name={"PropertyList3"}
                          handle={this.handleChange}
                        />
                      </div>
                      <div className="col-md-12"> 
                        <FormCheckGroup
                          title={catalog.Strings.profile.PropertyList2.title}
                          placeholder={
                            catalog.Strings.profile.PropertyList2.placeholder
                          }
                          value={this.state.filterData?.PropertyList2}
                          options={catalog.Config.PropertyList2}
                          name={"PropertyList2"}
                          handle={this.handleChange}
                        />
                      </div>
                      
                      </>
                    )}


                    <div className="col-md-12"> 
                      <FormCheckGroup
                        title={catalog.Strings.profile.PropertyList1.title}
                        placeholder={
                          catalog.Strings.profile.PropertyList1.placeholder
                        }
                        value={this.state.filterData?.PropertyList1}
                        options={catalog.Config.PropertyList1}
                        name={"PropertyList1"}
                        handle={this.handleChange}
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-9">
              <Card>
                <Card.Header>
                  <span>
                    Showing Products({this.state.storeItems.pagination.total})
                  </span>
                </Card.Header>
                <Card.Body>
                  <div className="store-items">
                    {this.state.storeItems.data.map((storeItem, index) => {
                      return (
                        <catalog.Card
                          key={index}
                          storeItem={storeItem}
                        ></catalog.Card>
                      );
                    })}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default StoreController;
