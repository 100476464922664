import { SiteService } from "../services/site";

export const StringsEn = {
  profile: {
    first_name: {
      title: "First Name",
      placeholder: "First Name",
      name: "first name",
      validate: async (value) => {
        
        const fieldName = StringsEn.profile.first_name.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length < 3) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 3 characters long.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    last_name: {
      title: "Last Name",
      placeholder: "Last Name",
      name: "last name",
      validate: async (value) => {
        
        const fieldName = StringsEn.profile.last_name.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        // if (value.length < 3) {
        //   return {
        //     valid: false,
            
        //     message: `The ${fieldName} must be at least 3 characters long.`,
        //   };
        // }
        return { valid: true,  message: "" };
      },
    }, 
    email: {
      title: "Email Id",
      placeholder: "Email Id",
      name: "email",
      validate: async (value) => {
        
        const fieldName = StringsEn.profile.email.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be a valid email address.`,
          };
        }
        if(await SiteService.checkEmailExist(value)){
          return {
            valid: false,            
            message: `The ${fieldName} has already been taken.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    mobile: {
      title: "Mobile No",
      placeholder: "Mobile No",
      name: "mobile",
      validate: async (value) => {
        
        const fieldName = StringsEn.profile.mobile.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        const mobilePattern = /^\d{10}$/;
        if (!mobilePattern.test(value)) {
          return {
            valid: false,
            message: `The ${fieldName} must be a valid 10-digit number.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    currentPassword:{
      title: "Current Password",
      placeholder: "Current Password",
      name: "current password",
      validate: async (value) => {
        
        const fieldName = StringsEn.profile.currentPassword.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length < 6) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 6 characters long.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    password: {
      title: "Password",
      placeholder: "Password",
      name: "password",
      validate: async (value) => {
        
        const fieldName = StringsEn.profile.password.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length < 6) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 6 characters long.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    cpassword: {
      title: "Re-enter Password",
      placeholder: "Re-enter Password",
      name: "re-enter password",
      confirm:(v1,v2)=>{
        const field1 = StringsEn.profile.password.name;
        const field2 = StringsEn.profile.cpassword.name;
        if(v1!==v2){
          return { confirm: false,  message: `The ${field2} must be same as ${field1}.` };
        }
        return { confirm: true,  message: "" };
      },
      validate: async (value) => {
        
        const fieldName = StringsEn.profile.cpassword.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length < 6) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 6 characters long.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    accept_legal_agreement:{
      title: "Legal Agreement",
      placeholder: "Legal Agreement",
      name: "legal agreement", 
      validate: async (value) => {
        
        const fieldName = StringsEn.profile.cpassword.name;
        if (!value) {
          return {
            valid: false,
            
            message: `Accept ${fieldName}  is required.`,
          };
        } 
        return { valid: true,  message: "" };
      },

    }
  },
  menu: {
    home: "Home",
    about: "About",
    products: "Products",
    contact: "Contact Us",
    account: "My Account",
    login: "Login",
    logout: "Logout",
    signup: "Sign Up",
  },
  subscription: {
    title: "Newsletter",
    description: "Subscribe to get the latest in updates and news",
    button: "Subscribe Now",
    success:
      "Thank you for subscribing. We look forward to staying in touch and bringing you great content!",
  },
  news: {
    popular_category: "POPULAR CATEGORY",
    recent_more: "RECENT MORE NEWS",
  },
};
