import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { Component } from "react";
import { InputGroup, Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
class FormDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props, 
    };
    this.inputRef = React.createRef();
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  formatDateYMD(date) {
    if (!date) return '';
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
  render() {
    return (
      <div className="input-field">
        <Form.Group className="mb-3" controlId={this.state.id}>
          <Form.Label>{this.state.title}</Form.Label>
          <InputGroup hasValidation>
            <DatePicker
              className="form-control"
              onChange={(date)=>{
                const newdate=this.formatDateYMD(date);
                this.state.handle({
                  target:{
                    name:this.state.name,
                    value:newdate
                  }
                })
              }} 
              value={this.state.value ?? ""}
              isInvalid={this.state.error ? true : false}
              name={this.state.name}
              isClearable
              placeholder={this.state.placeholder}
            /> 
            <Form.Control.Feedback type="invalid">
              {this.state.error}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </div>
    );
  }
}
export default FormDatePicker;
