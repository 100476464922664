import productImg from "./../images/productbox-img1.png";
const ProductCard = () => {
    return (
        <div className="productCol">
            <div className="productCol1">
                <div className="prd-row1">
                    <h2>E-Filling- Third Party</h2>
                    <a href="#" className="prdt-btn1">₹ 2,682/-</a>
                </div>
                <div className="prd-row2">
                    <img src={productImg} alt="X" />
                </div>
            </div>
            <div className="productCol2">
                <div className="prdt-filter">
                    <span className="filter1">Paperback</span>
                    <span className="filter2">E-book</span>
                    <span className="filter3">Rent</span>
                </div>
                <div className="prdt-content">
                    <h4>E-Filling-Third Party</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam at ipsum pretium</p>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
