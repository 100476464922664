import { SiteService } from "../services/site";

export const StringsHi = {
  profile: {
    first_name: {
      title: "पहला नाम",
      placeholder: "पहला नाम",
      name: "पहला नाम",
      validate: async (value) => {
        
        const fieldName = StringsHi.profile.first_name.name;
        if (!value) {
          return {
            valid: false,
            
            message: `${fieldName} फ़ील्ड आवश्यक है.`,
          };
        }
        if (value.length < 3) {
          return {
            valid: false,
            
            message: `${fieldName} कम से कम 3 अक्षर लंबा होना चाहिए.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    last_name: {
      title: "उपनाम",
      placeholder: "उपनाम",
      name: "उपनाम",
      validate: async (value) => {
        
        const fieldName = StringsHi.profile.last_name.name;
        if (!value) {
          return {
            valid: false,
            
            message: `${fieldName} फ़ील्ड आवश्यक है.`,
          };
        }
        // if (value.length < 3) {
        //   return {
        //     valid: false,
            
        //     message: `${fieldName} कम से कम 3 अक्षर लंबा होना चाहिए.`,
        //   };
        // }
        return { valid: true,  message: "" };
      },
    }, 
    email: {
      title: "ईमेल आईडी",
      placeholder: "ईमेल आईडी",
      name: "ईमेल",
      validate: async (value) => {
        
        const fieldName = StringsHi.profile.email.name;
        if (!value) {
          return {
            valid: false,
            
            message: `${fieldName} फ़ील्ड आवश्यक है.`,
          };
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
          return {
            valid: false,
            
            message: `${fieldName} एक वैध ईमेल पता होना चाहिए.`,
          };
        }
        if(await SiteService.checkEmailExist(value)){
          return {
            valid: false,            
            message: `${fieldName} पहले ही लिया जा चुका है`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    mobile: {
      title: "मोबाइल नंबर",
      placeholder: "मोबाइल नंबर",
      name: "मोबाइल",
      validate: async (value) => {
        
        const fieldName = StringsHi.profile.mobile.name;
        if (!value) {
          return {
            valid: false,
            
            message: `${fieldName} फ़ील्ड आवश्यक है.`,
          };
        }
        const mobilePattern = /^\d{10}$/;
        if (!mobilePattern.test(value)) {
          return {
            valid: false,
            message: `${fieldName} एक वैध 10-अंकीय संख्या होनी चाहिए.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    currentPassword:{
      title: "वर्तमान पासवर्ड",
      placeholder: "वर्तमान पासवर्ड",
      name: "वर्तमान पासवर्ड",
      validate: async (value) => {
        
        const fieldName = StringsHi.profile.currentPassword.name;
        if (!value) {
          return {
            valid: false,
            
            message: `${fieldName} फ़ील्ड आवश्यक है.`,
          };
        }
        if (value.length < 6) {
          return {
            valid: false,
            
            message: `${fieldName} कम से कम 6 अक्षर लंबा होना चाहिए.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    password: {
      title: "पासवर्ड",
      placeholder: "पासवर्ड",
      name: "पासवर्ड",
      validate: async (value) => {
        
        const fieldName = StringsHi.profile.password.name;
        if (!value) {
          return {
            valid: false,
            
            message: `${fieldName} फ़ील्ड आवश्यक है.`,
          };
        }
        if (value.length < 6) {
          return {
            valid: false,
            
            message: `${fieldName} कम से कम 6 अक्षर लंबा होना चाहिए.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    cpassword: {
      title: "पासवर्ड फिर से दर्ज करें",
      placeholder: "पासवर्ड फिर से दर्ज करें",
      name: "पासवर्ड फिर से दर्ज करें",
      confirm:(v1,v2)=>{
        const field1 = StringsHi.profile.password.name;
        const field2 = StringsHi.profile.cpassword.name;
        if(v1!==v2){
          return { confirm: false,  message: `The ${field2} must be same as ${field1}.` };
        }
        return { confirm: true,  message: "" };
      },
      validate: async (value) => {
        
        const fieldName = StringsHi.profile.cpassword.name;
        if (!value) {
          return {
            valid: false,
            
            message: `${fieldName} फ़ील्ड आवश्यक है.`,
          };
        }
        if (value.length < 6) {
          return {
            valid: false,
            
            message: `${fieldName} कम से कम 6 अक्षर लंबा होना चाहिए.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },

    accept_legal_agreement:{
      title: "Legal Agreement",
      placeholder: "Legal Agreement",
      name: "legal agreement", 
      validate: async (value) => {
        
        const fieldName = StringsHi.profile.cpassword.name;
        if (!value) {
          return {
            valid: false,
            
            message: `Accept ${fieldName}  is required.`,
          };
        } 
        return { valid: true,  message: "" };
      },

    }
  },
  menu: {
    home: "घर",
    about: "के बारे में",
    products: "उत्पादों",
    contact: "संपर्क करें",
    account: "खाता",
    login: "लॉग इन करें",
    logout: "लॉग आउट",
    signup: "साइन अप करें",
  },
  subscription: {
    title: "समाचार पत्रिका",
    description: "नवीनतम अपडेट और समाचार पाने के लिए सदस्यता लें",
    button: "अब सदस्यता लें",
    success: "सदस्यता लेने के लिए धन्यवाद। हम संपर्क में बने रहने और आपके लिए बेहतरीन सामग्री लाने के लिए तत्पर हैं!"
  },
  news: {
    popular_category: "लोकप्रिय श्रेणी",
    recent_more: "हाल की और खबरें",
  },
};
