import React, { Component } from "react";
import { AppContext } from "../config/common";
import { StoreService } from "../services/store";
import Catalog from "../Catalog";
import { Link, Navigate } from "react-router-dom"; 
import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";

class StoreItemDetailController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props); 
    this.state = {
      ...props,
      storeItem: {},
      storeName: "",
      storeId:"",
      storeSlug:"",
      loaded: false,
      modal: false,
      isSubmit: false,
      success: null,
      error: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchStoreItem();
  }

  componentDidUpdate() {
    if (this.state.store !== this.state.storeName||this.state.id !== this.state.storeId||this.state.slug !== this.state.storeSlug) {
      this.fetchStoreItem();
    }
  }

  handleClose=()=>{
    const modal=false;
    this.setState({modal})
  }
  ucfirst=(string)=>{
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  formatDateYMD=(date)=> {
    if (!date) return '';
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  handleShow=()=>{
    const modal=true;
    this.setState({modal})
  }
  handleSubmit= async ()=>{
    let isSubmit=true;
    this.setState({isSubmit})
    const settings= this.context;
    const orderdate = new Date();
    const expdate = new Date();
    expdate.setMonth(expdate.getMonth()+1);
    const order = await StoreService.createOrder({
      user_id:Catalog.Session.user.id,
      store_id:this.ucfirst(this.state.store),
      item_id:this.state.storeItem.id.toString(),
      purchase_date:this.formatDateYMD(orderdate),
      expiry_date:this.formatDateYMD(expdate),
      item_detail:this.state.storeItem,
      Price:this.state.storeItem.Price,
    },settings);
    let success,error=null;
    if(order.id||(order.data&&order.data.id)){
      success="Order was placed"; 
    }else{
      error = "Error: plese try again later ";
    }

    const modal=false;
    isSubmit=false;
    this.setState({modal,isSubmit,success,error})
  }

  fetchStoreItem = async ()=> {
    const storeName = this.state.store;
    const storeId = this.state.id;
    const storeSlug = this.state.slug;   
    let loaded=false; 
    this.setState({ storeName ,storeId ,storeSlug,loaded});
    const storeItem = await StoreService.fetchStoreItem(storeName,storeId ,storeSlug);
    loaded=true;
    this.setState({storeItem,loaded});
  }
  render() {
    let catalog = Catalog.Book;
    switch (this.state.store) {
        case "books":
            catalog = Catalog.Book;
            break;
        case "lawyers":
            catalog = Catalog.Lawyer;
            break;
        case "firms":
            catalog = Catalog.Firm;
            break;

        default:
          return (
            <>
              <Navigate replace to="/404" />
            </>
          );
    }
    if (this.state.loaded === true) {
        if(!this.state.storeItem.id){
            return (
                <div className="store-wrap">
                <div className="top-action">
                   <Link to={'/catalogs/'+this.state.store}> &lt; Back</Link>
                </div>
                    <div className="store-item">
                        <div className="store-item-info">
                            <div className="store-item-title">
                                <span className="title">Item Not Fount</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
      return (
        <div className="store-wrap">
            {
              this.state.error&&(<>
              <Alert variant="danger" dismissible>
                <p>
                  {this.state.error}
                </p>
              </Alert>
              
              </>)
            }
            {
              this.state.success&&(<>
              <Alert variant="success" dismissible>
                <p>
                  {this.state.success}
                </p>
              </Alert>
              
              </>)
            }
             <div className="top-action">
                <Link to={'/catalogs/'+this.state.store}> &lt; Back</Link>
             </div>
            <div className="store-item-detail">
                <div className="store-item-avathar">
                    <div className="store-item-main">
                      <img  src={this.state.storeItem.image}  alt={this.state.storeItem.Title} />                      
                    </div>
                    <div className="store-item-gallery">
                      {
                        this.state.storeItem.gallery.map((v,i)=>{
                          return <div className="store-gallery-item" key={i}>
                                      <img  src={v.image}  alt={this.state.storeItem.Title} />
                                  </div>
                        })
                      }
                  </div>
                </div>
                
                <div className="store-item-info">
                    <div className="store-item-title">
                        <div className="title">{this.state.storeItem.Title}</div>
                        <div className="subtitle">{this.state.store==="books"&&(<>By </>)} {this.state.storeItem.SubTitle}</div>
                    </div>
                    <div className="store-item-detail">
                    {this.state.store==="books"&&(<p>{this.state.storeItem.Category}</p>)}
                        <table>
                            <tbody> 
                                {Catalog.Session.isLogined&&this.state.store!=="books"&&this.state.storeItem.Identifier&&(<tr>
                                    <th>{catalog.Strings.profile.Identifier.title}</th>
                                    <td>: {this.state.storeItem.Identifier}</td>
                                </tr>)}
                                {this.state.storeItem.DisplayDate&&(<tr>
                                    <th>{catalog.Strings.profile.DisplayDate.title}</th>
                                    <td>: {this.state.storeItem.DisplayDate}</td>
                                </tr>)}
                                {this.state.storeItem.Property1&&(<tr>
                                    <th>{catalog.Strings.profile.Property1.title}</th>
                                    <td>: {this.state.storeItem.Property1.map(itm=> <Badge bg="dark"> {itm}</Badge>)}</td>
                                </tr>)}
                                {this.state.storeItem.Property2&&(<tr>
                                    <th>{catalog.Strings.profile.Property2.title}</th>
                                    <td>: {this.state.storeItem.Property2.map(itm=> <Badge bg="dark"> {itm}</Badge>)}</td>
                                </tr>)}
                                {this.state.storeItem.PropertyList1&&(<tr>
                                    <th>{catalog.Strings.profile.PropertyList1.title}</th>
                                    <td>: {this.state.storeItem.PropertyList1.map(itm=> <Badge bg="dark"> {itm}</Badge>)}</td>
                                </tr>)}
                                {Catalog.Session.isLogined&&this.state.storeItem.PropertyList2&&(<tr>
                                    <th>{catalog.Strings.profile.PropertyList2.title}</th>
                                    <td>: {this.state.storeItem.PropertyList2.map(itm=> <Badge bg="dark"> {itm}</Badge>)}</td>
                                </tr>)}
                                {this.state.storeItem.PropertyList3&&(<tr>
                                    <th>{catalog.Strings.profile.PropertyList3.title}</th>
                                    <td>: {this.state.storeItem.PropertyList3.map(itm=> <Badge bg="dark"> {itm}</Badge>)}</td>
                                </tr>)}
                                {Catalog.Session.isLogined&&this.state.store!=="books"&&(<>
                                  {this.state.storeItem.contact.email_address&&(<tr>
                                      <th>{catalog.Strings.profile.email_address.title}</th>
                                      <td>: {this.state.storeItem.contact.email_address}</td>
                                  </tr>)}
                                  {this.state.storeItem.contact.phone_number&&(<tr>
                                      <th>{catalog.Strings.profile.phone_number.title}</th>
                                      <td>: {this.state.storeItem.contact.phone_number}</td>
                                  </tr>)}
                                  {this.state.storeItem.contact.website_link&&(<tr>
                                      <th>{catalog.Strings.profile.website_link.title}</th>
                                      <td>: <a href={this.state.storeItem.contact.website_link} rel="noreferrer" target="_blank">{this.state.storeItem.contact.website_link}</a> </td>
                                  </tr>)}
                                  {this.state.storeItem.contact.address&&(<tr>
                                      <th>{catalog.Strings.profile.address.title}</th>
                                      <td>: {this.state.storeItem.contact.address}</td>
                                  </tr>)}
                                </>)}

                                {Catalog.Session.isLogined&&this.state.storeItem.Price&&(<tr>
                                    <th>{catalog.Strings.profile.Price.title}</th>
                                    <td>: {this.state.storeItem.Price}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className="store-action">
                      {Catalog.Session.isLogined?(<>
                        {this.state.store==="books"&&(<>
                        
                          <Link  className="book-now-btn" onClick={this.handleShow} >Book Now</Link>
                        <Modal show={this.state.modal} onHide={this.handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>{this.state.storeItem.Title}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                          <table>
                            <tbody>
                                {this.state.storeItem.Identifier&&(<tr>
                                    <th>{catalog.Strings.profile.Identifier.title}</th>
                                    <td>: {this.state.storeItem.Identifier}</td>
                                </tr>)}
                                {this.state.storeItem.DisplayDate&&(<tr>
                                    <th>{catalog.Strings.profile.DisplayDate.title}</th>
                                    <td>: {this.state.storeItem.DisplayDate}</td>
                                </tr>)}
                                {this.state.storeItem.Property1&&(<tr>
                                    <th>{catalog.Strings.profile.Property1.title}</th>
                                    <td>: {this.state.storeItem.Property1.map(itm=> <Badge bg="dark"> {itm}</Badge>)}</td>
                                </tr>)}
                                {this.state.storeItem.Property2&&(<tr>
                                    <th>{catalog.Strings.profile.Property2.title}</th>
                                    <td>: {this.state.storeItem.Property2.map(itm=> <Badge bg="dark"> {itm}</Badge>)}</td>
                                </tr>)}
                                {this.state.storeItem.PropertyList1&&(<tr>
                                    <th>{catalog.Strings.profile.PropertyList1.title}</th>
                                    <td>: {this.state.storeItem.PropertyList1.map(itm=> <Badge bg="dark"> {itm}</Badge>)}</td>
                                </tr>)}
                                {Catalog.Session.isLogined&&this.state.storeItem.PropertyList2&&(<tr>
                                    <th>{catalog.Strings.profile.PropertyList2.title}</th>
                                    <td>: {this.state.storeItem.PropertyList2.map(itm=> <Badge bg="dark"> {itm}</Badge>)}</td>
                                </tr>)}
                                {this.state.storeItem.PropertyList3&&(<tr>
                                    <th>{catalog.Strings.profile.PropertyList3.title}</th>
                                    <td>: {this.state.storeItem.PropertyList3.map(itm=> <Badge bg="dark"> {itm}</Badge>)}</td>
                                </tr>)}  

                                {Catalog.Session.isLogined&&this.state.storeItem.Price&&(<tr>
                                    <th>{catalog.Strings.profile.Price.title}</th>
                                    <td>: {this.state.storeItem.Price}</td>
                                </tr>)}
                            </tbody>
                        </table>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                              Close
                            </Button>
                            <Button variant="primary" onClick={this.handleSubmit}   disabled={this.state.isSubmit}>
                              Confirm
                              {this.state.isSubmit&&(<Spinner animation="border" role="status" >
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>)}
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        
                        
                        </>)}
                        
                      </>):(<>
                      <Link to={'/login'} className="book-now-btn" onClick={()=>{
                        Catalog.RediarectLogin=`/catalogs/${this.state.store}/${this.state.id}/${this.state.slug}`
                      }} >{this.state.store==="books"?(<> Book Now</>):(<>Contact</>)}</Link>
                      </>)}
                    </div>
                </div>
                <div className="store-item-content" dangerouslySetInnerHTML={{__html:this.state.storeItem.Detail1||''}}></div>
            </div> 
        </div>
      );
    } else {
      return <>Loading</>;
    }
  }
}
export default StoreItemDetailController;
