import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import "./../css/widget/SearchForm.css";
class SearchForm extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            categorys: props.categorys,
            placeholder:props.placeholder,
            handleSubmit:props.handleSubmit,
            formClass:props.formClass
        };   
    }    
    static getDerivedStateFromProps(props, state) {
        return {
            categorys: props.categorys,
            placeholder:props.placeholder,
            handleSubmit:props.handleSubmit,
            formClass:props.formClass
        };
    }

    render() {
        return (
            <>
                <Form action='/search' className={"search-form "+this.state.formClass} onSubmit={ this.state.handleSubmit } >
                    {
                        (this.state.categorys)?(
        
                            <Form.Label className="custom-select"> 
                            <Form.Select name='c' aria-label="Default select example">
                            <option value={"all"}>All</option>
                            {                                                    
                                this.state.categorys.map((item,key)=>{
                                    return <option key={"search-form-option-"+key} value={item.slug}>{item.title}</option>
                                })
                            }
                            </Form.Select>
                        </Form.Label>
                        ):(<></>)
                    }
                    <Form.Control type="search" name='q' className="form-control" placeholder={ this.state.placeholder } />
                    <Button className="search-btn" type="submit"></Button>
                </Form>
            </>
          );
    }
} 

export default SearchForm;