import React, { Component } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import FormCard from "../widgets/FormCard";
import FormField from "../widgets/FormField";

import "./../css/widget/SubscribeModal.css";

import { SiteService } from "./../services/site"; 
import Catalog from "../Catalog";

class SubscribeModal extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      formData: {
        name: null,
        email: null,
      },
      errorData: {
        name: null,
        email: null,
      },
      subscriptionerror: null,
      subscriptionsuccess:null
    };
  }

  componentDidMount() {
    const session = window.localStorage.getItem("dailylawtimes");
    let show = true;
    let num = 1;
    let subscription = null;
    if (session && session !== "") {
      try {
        let sessionData = JSON.parse(session);
        num = parseInt((sessionData.visit ?? 0).toString()) + num;
        subscription = sessionData.subscription ?? null;
      } catch (error) {}
    }
    if ((num !== 6 && num !== 20) || subscription !== null) {
      show = false;
    }
    this.setState({ show });
    window.localStorage.setItem(
      "dailylawtimes",
      JSON.stringify({
        subscription: subscription,
        visit: num,
      })
    );
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = this.state.formData;
    let errorData = {};
    let isInValid = false;
    for (const name in formData) {
      errorData[name] = "The " + name + " field is required";
      if (Object.hasOwnProperty.call(formData, name)) {
        if (formData[name] && formData[name] !== "") {
          errorData[name] = null;
        } else {
          isInValid = true;
        }
      } else {
        isInValid = true;
      }
    }
    let subscriptionerror = null;
    this.setState({ errorData, subscriptionerror });
    if (!isInValid) {
      const ndate = new Date();
      const userid =
        formData.name
          .toLowerCase()
          .replace(/[^\w ]+/g, "")
          .replace(/ +/g, "-") +
        "" +
        ndate.getTime();
      const subscription = await SiteService.subscribeNewsletter({
        name: formData.name,
        first_name: formData.name,
        email: formData.email,
        username: userid,
        password: ndate.getTime().toString(),
        role: "subscriber",
      });

      const session = window.localStorage.getItem("dailylawtimes");
      let num = 1;
      if (session && session !== "") {
        try {
          let sessionData = JSON.parse(session);
          num = parseInt((sessionData.visit ?? 0).toString()) + num;
        } catch (error) {}
      }
      if (subscription.user) {
        window.localStorage.setItem(
          "dailylawtimes",
          JSON.stringify({
            subscription: subscription,
            visit: num,
          })
        );
        const show = false;

        formData = {
          name: null,
          email: null,
        };
        errorData = {
          name: null,
          email: null,
        };
        const subscriptionsuccess=Catalog.Strings.subscription.success
        this.setState({ show, formData, errorData ,subscriptionsuccess});
      } else {
        if (subscription.error) {
          subscriptionerror = subscription.error.message;
          this.setState({ subscriptionerror });
        }
        window.localStorage.setItem(
          "dailylawtimes",
          JSON.stringify({
            subscription: null,
            visit: num,
          })
        );
      }
    }
    return !isInValid;
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    this.setState({ formData });
    if (!value || value === "") {
      const errorData = {
        ...this.state.errorData,
        [name]: "The " + name + " field is required",
      };
      this.setState({ errorData });
    } else {
      const errorData = {
        ...this.state.errorData,
        [name]: null,
      };
      this.setState({ errorData });
    }
  };
  handleClose = (e) => {
    const show = false;
    this.setState({ show });
  };

  render() { 
    return (
      <Modal
        className="news-modal"
        show={this.state.show}
        onHide={this.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title> { Catalog.Strings.subscription.title } </Modal.Title>
          <p> { Catalog.Strings.subscription.description } </p>
        </Modal.Header>
        <Modal.Body>
          {this.state.subscriptionerror ? (
            <Alert variant={"error"}>{this.state.subscriptionerror}</Alert>
          ) : (
            <></>
          )}

        {this.state.subscriptionsuccess ? (
            <Alert variant={"success"}>{this.state.subscriptionsuccess}</Alert>
          ) : (
            <></>
          )}
          <FormCard handle={this.handleSubmit}>
            <FormField
              placeholder={"First Name"}
              value={this.state.formData?.name}
              error={this.state.errorData?.name}
              name={"name"}
              handle={this.handleChange}
            />
            <FormField
              placeholder={"Your Email"}
              value={this.state.formData?.email}
              error={this.state.errorData?.email}
              name={"email"}
              handle={this.handleChange}
            />
            <Button variant="primary" type="submit">
            {Catalog.Strings.subscription.button}
            </Button>
          </FormCard>
        </Modal.Body>
        <Modal.Footer>
          <p>We respect your privacy.</p>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SubscribeModal;
