import React, { Component } from "react";
import Slider from "react-slick"; 

import "./../css/widget/BrandSlider.css";
class BrandSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: props.products,
      ismobile: props.ismobile ?? false,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      products: props.products,
      ismobile: props.ismobile ?? false,
    };
  }
  render() {
    return (
      <Slider
        className={
          this.state.products.length > 7 || this.state.ismobile
            ? "product-slider"
            : "product-slider no-slide no-slide-" + this.state.products.length
        }
        responsive={[
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 660,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ]}
        infinite={this.state.products.length > 7 ? true : false}
        slidesToShow={7}
        slidesToScroll={1}
      >
        {this.state.products.map((product) => {
          const link=product.comming_soon?"/comming-soon": product.link; 
          return product.new_tab ? (
            <a
              key={"product-" + product.id}
              href={link }
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="brand-list">
                <img src={product.image} alt={product.title} />
                <span className="brand-name">{product.title}</span>
              </div>
            </a>
          ) : (
            <a href={link} key={"product-" + product.id}>
              <div className="brand-list">
                <img src={product.image} alt={product.title} />
                <span className="brand-name">{product.title}</span>
              </div>
            </a>
          );
        })}
      </Slider>
    );
  }
}

export default BrandSlider;
