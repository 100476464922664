import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link, Navigate } from "react-router-dom";
import SearchForm from "./../widgets/SearchForm";
import NavTabBar from "../widgets/NavTabBar";
import shareicon1 from "./../images/shareicon.svg";
import telegram1 from "./../images/telegram-icon.svg";
import whatsapp1 from "./../images/whatsappicon.svg";
import facebook1 from "./../images/facebook-icon.svg";
import outline1 from "./../images/outline-icon.svg";
import linkedin1 from "./../images/linkedin-icon.svg";
import { Card, Badge } from "react-bootstrap";
import SideBarAdCard from "../widgets/SideBarAdCard";
import RecentNews from "../widgets/RecentNews";
import { NewsService } from "./../services/news";
import { AdsService } from "./../services/ads";

import noimage from "./../images/dailytime-noimg.png";

import { dateFormat, AppContext } from "./../config/common";
import { APP_ENDPOINT } from "../config/constant";
import { SiteService } from "../services/site";
class NewsDetailController extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.divRef = React.createRef();
    this.contentRef = React.createRef(); 
    this.state = {
      isBlog: true,
      category: [],
      relatedNews: [],
      products: {
        data: [],
      },
      ads: [],
      cads: [],
      newsdata: {},
      pagedata:{},
      is404: false,
      containerRowClass: "row row-two ",
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    // this.fetchProducts();
    this.fetchFullWidthAdd();
    this.fetchCategory();
    this.fetchPostBySlug();
  }
  componentDidUpdate() {
    if (
      this.state.newsdata &&
      this.state.newsdata.slug &&
      this.state.newsslug
    ) {
      if (this.state.newsdata.slug !== this.state.newsslug) {
        this.fetchPostBySlug();
      }
    }
    if (
      this.state.pagedata &&
      this.state.pagedata.name &&
      this.state.newsslug
    ) {
      if (this.state.pagedata.name !== this.state.newsslug) {
        this.fetchPostBySlug();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = async (e) => {
    const scrollTop = window.scrollY;
    const divElement = this.divRef.current;
    let containerRowClass = "row row-two ";
    if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const divTop = rect.top + scrollTop - 100;
      if (scrollTop >= divTop) {
        containerRowClass = "row row-two stiky-row";
      }
    }
    this.setState({ containerRowClass });
  };
  fetchFullWidthAdd = async () => {
    const ads = await AdsService.fetchFullWidthAdd();
    this.setState({ ads });
  };
  fetchInnerContentAdd = async () => {
    const cads = await AdsService.fetchInnerContentAdd();
    this.setState({ cads });
    const contentElement = this.contentRef.current;
    const plist = contentElement.querySelectorAll("p");
    const hasad = contentElement.querySelectorAll("a.ad-link");

    const min = 0;
    const max = cads.length - 1;
    let rdm = 0;
    if (min < max) {
      rdm = Math.round(min + Math.random() * (max - min));
    }

    if (plist.length > 3 && cads.length > rdm && hasad.length === 0) {
      const alink = document.createElement("a");
      alink.href = cads[rdm].link;
      alink.className = "ad-link";
      plist[2].parentNode.insertBefore(alink, plist[2].nextSibling);
      const img = document.createElement("img");
      img.src = cads[rdm].image;
      img.alt = cads[rdm].title;
      alink.appendChild(img);
    }
  };
  // fetchProducts = async () => {
  //   const products = await NewsService.fetchProducts();
  //   this.setState({ products });
  // };
  fetchCategory = async () => {
    const category = await NewsService.fetchCategory();
    this.setState({ category });
  };
  fetchPostBySlug = async () => {
    const slug = this.state.newsslug;
    const pagedata = await SiteService.fetchPagebySlug(slug);
    const newsdata = await NewsService.fetchPostBySlug(slug);
    const is404 = (newsdata && newsdata.id) || ( pagedata && pagedata.id )? false : true;
    this.setState({ newsdata,pagedata, is404 });
    if(newsdata && newsdata.id){
      this.fetchInnerContentAdd();
      this.updatePostView(newsdata);
      this.fetchRelatedNews(newsdata);
      const isBlog=true;
      this.setState({ isBlog });
    }
    if(pagedata && pagedata.id){
      const isBlog=false;
      this.setState({ isBlog });
    }
    window.scrollTo(0, 0);
  };
  updatePostView = async (news) => {
    if (news && news.id) {
      const newsdata = await NewsService.updatePostView(news);
      this.setState({ newsdata });
    }
  };
  fetchRelatedNews = async (newsdata) => {
    if (
      newsdata &&
      newsdata.categories &&
      newsdata.categories.data &&
      newsdata.categories.data[0]
    ) {
      const slug = newsdata.categories.data[0].attributes.slug;
      const { news } = await NewsService.fetchPostByCategory(slug);
      const relatedNews = news;
      this.setState({ relatedNews });
    }
  };

  handleSubmit = async (e) => {
    console.log("Form submitted:");
  }; 
  render() {
    const min = 0;
    const max = this.state.ads.length - 1;
    let random = 0;
    if (min < max) {
      random = min + Math.random() * (max - min);
    }
    // const { settings } = this.context;
    return (
      <>
        {this.state.is404 ? <Navigate replace to="/404" /> : <></>}
        <Helmet>
          <title>{this.state.newsdata.title}</title>
          <meta name="title" content={this.state.newsdata.title} />
          <meta
            name="description"
            content={(this.state.newsdata.content ?? "").replace(
              /<[^>]+>/g,
              ""
            )}
          />
          <meta
            name="keywords"
            content={(this.state.newsdata.newstags ?? []).join(" , ")}
          />

          <meta
            name="url"
            property="og:url"
            content={APP_ENDPOINT + "/" + this.state.newsslug}
          />
          <meta property="og:type" content="website" />
          <meta
            name="title"
            property="og:title"
            content={this.state.newsdata.title}
          />
          <meta
            name="description"
            property="og:description"
            content={(this.state.newsdata.content ?? "").replace(
              /<[^>]+>/g,
              ""
            )}
          />
          <meta
            name="image"
            property="og:image"
            content={this.state.newsdata.image}
          />

          <meta name="twitter:card" content="summary" />
          <meta property="twitter:title" content={this.state.newsdata.title} />
          <meta
            property="twitter:description"
            content={(this.state.newsdata.content ?? "").replace(
              /<[^>]+>/g,
              ""
            )}
          />
          <meta property="twitter:image" content={this.state.newsdata.image} />
          <meta property="twitter:url" content={APP_ENDPOINT} />
        </Helmet>
        <div className="news-dtl-wrap">
          {/* {settings.show_products && this.state.newsdata&&this.state.newsslug&&this.state.newsslug===this.state.newsdata.slug  ? (
            <div className="brand-wrp">
              <div className="slider-wrap slide-desk">
                <BrandSlider
                  products={this.state.products.data}
                  ismobile={false}
                ></BrandSlider>
              </div>
              <div className="slider-wrap slide-mob">
                <BrandSlider
                  products={this.state.products.data}
                  ismobile={true}
                ></BrandSlider>
              </div>
            </div>
          ) : (
            <></>
          )} */}
          {this.state.ads && this.state.ads.length > 0 ? (
            <div className="ad-wrp">
              <a
                href={this.state.ads[random].link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="ad-image-list">
                  <img
                    src={this.state.ads[random].image}
                    alt={this.state.ads[random].title}
                  />
                </div>
              </a>
            </div>
          ) : (
            <></>
          )}
          <div className="row main-menu">
            <div className="col-md-9">
              <NavTabBar
                categorys={this.state.category}
                active={this.state.catslug ?? "all"}
              ></NavTabBar>
            </div>
            <div className="col-md-3">
              <div className="search-news">
                <SearchForm
                  placeholder={"Search News"}
                  handleSubmit={this.handleSubmit}
                />
              </div>
            </div>
          </div>
          <div className={this.state.containerRowClass} ref={this.divRef}>
            {
              this.state.isBlog?(<>
              
              <ul className="social-newsdtl">
              <li>
                <a
                  href="#share"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      APP_ENDPOINT + "/" + this.state.newsslug
                    );
                  }}
                >
                  <img src={shareicon1} alt="logo" />
                </a>
              </li>

              <li>
                <a
                  href={
                    "https://t.me/share/url?url=" +
                    APP_ENDPOINT +
                    "/" +
                    this.state.newsslug +
                    "&text=" +
                    this.state.newsdata.title
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={telegram1} alt="logo" />
                </a>
              </li>
              {/* "+((settings.phone??"").replaceAll(/\D/g,''))+" */}
              <li>
                <a
                  href={
                    "https://wa.me/?text=" +
                    APP_ENDPOINT +
                    "/" +
                    this.state.newsslug
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={whatsapp1} alt="logo" />
                </a>
              </li>
              <li>
                <a
                  href={
                    "https://www.facebook.com/sharer/sharer.php?u=" +
                    APP_ENDPOINT +
                    "/" +
                    this.state.newsslug
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook1} alt="logo" />
                </a>
              </li>
              {/* 
                      <li>
                        <Link to={"#"}>
                          <img src={instagram1} alt="instagram" />
                        </Link>
                      </li>
                     */}

              <li>
                <a
                  href={
                    "https://twitter.com/intent/tweet?text=" +
                    this.state.newsdata.title +
                    "&url=" +
                    APP_ENDPOINT +
                    "/" +
                    this.state.newsslug
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={outline1} alt="X" />
                </a>
              </li>

              <li>
                <a
                  href={
                    "https://www.linkedin.com/shareArticle?mini=true&title=" +
                    this.state.newsdata.title +
                    "&url=" +
                    APP_ENDPOINT +
                    "/" +
                    this.state.newsslug
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin1} alt="LinkedIn" />
                </a>
              </li>
            </ul>
            <div className="col-md-9 news-dtl-col-md-9">
              <div className="news-dtl-col">
                <div className="news-dtl-head">
                  {/* <h4>The Times of India</h4> */}
                  <h1>{this.state.newsdata.title}</h1>
                  <div className="newsdtl-ifo">
                    {this.state.newsdata.user ? (
                      <span className="sub">
                        Story by {this.state.newsdata.user.first_name}{" "}
                        {this.state.newsdata.user.last_name}
                      </span>
                    ) : (
                      <></>
                    )}
                    <span className="date">
                      {dateFormat(this.state.newsdata.publish_date, "M d, Y")}
                    </span>
                    <span className="min">2 min read</span>
                    <span className="viewcount">
                      {this.state.newsdata.views_count}
                    </span>
                  </div>
                </div>
                {this.state.newsdata.image && !this.state.newsdata.youtube ? (
                  <div className="newsdtl-img">
                    <Card.Img src={this.state.newsdata.image} alt="" />
                  </div>
                ) : (
                  <></>
                )}

                {this.state.newsdata.youtube ? (
                  <div className="newsdtl-img">
                    <iframe
                      id={"ytPlayer" + this.state.newsdata.id}
                      title={this.state.newsdata.title}
                      width="100%"
                      height="390"
                      src={
                        "https://www.youtube.com/embed/" +
                        this.state.newsdata.youtube +
                        "?controls=1"
                      }
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
                      allowfullscreen
                      frameborder="0"
                    ></iframe>
                  </div>
                ) : (
                  <></>
                )}

                <div
                  ref={this.contentRef}
                  dangerouslySetInnerHTML={{
                    __html: this.state.newsdata.content,
                  }}
                ></div>
                {this.state.newsdata && this.state.newsdata.tags ? (
                  <div className="newsdtl-tags">
                    <Badge bg="dark" className="tag">
                      TAG
                    </Badge>
                    {this.state.newsdata.tags.data
                      // .filter((tg) => {
                      //   return (
                      //     tg.attributes.name !== "&#038;" &&
                      //     tg.attributes.name !== "&amp;"
                      //   );
                      // })
                      .map((t, k) => {
                        return (
                          <Badge
                            key={k}
                            bg="secondary"
                            className="tag m-1"
                            dangerouslySetInnerHTML={{
                              __html: t.attributes.name,
                            }}
                            onClick={() => {
                              window.open(
                                "/tags/" + t.attributes.slug,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          ></Badge>
                        );
                      })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="news-footer-col">
                {this.state.relatedNews.length > 1 && (
                  <div className="related-news">
                    <span className="related-txt latest-txt">Related News</span>
                    <div className="row">
                      {this.state.relatedNews
                        .filter((i, k) => this.state.newsdata.id !== i.id)
                        .filter((i, k) => k < 4)
                        .map((item, k) => (
                          <div key={k} className="col-md-3 ">
                            <Card className="related-block">
                              <Card.Body>
                                <Link to={"/" + item.slug}>
                                  <span>{item.title}</span>
                                  <div className="related-img">
                                    <img src={item.image ?? noimage} alt="" />
                                  </div>
                                </Link>
                              </Card.Body>
                            </Card>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <div className="other-links mt-2">
                  <Link to="/">
                    <span className="link">Home</span>
                  </Link>{" "}
                  |{" "}
                  <Link to="/about">
                    <span className="link">About</span>
                  </Link>{" "}
                  |{" "}
                  <Link to="/subscribe">
                    <span className="link">Subscribe</span>
                  </Link>{" "}
                  |{" "}
                  <Link to="/career">
                    <span className="link">Career</span>
                  </Link>{" "}
                  |{" "}
                  <Link to="/terms-of-service">
                    <span className="link">Terms of Service</span>
                  </Link>{" "}
                  |{" "}
                  <Link to="/privacy-policy">
                    <span className="link">Privacy Policy</span>
                  </Link>{" "}
                  |{" "}
                  <Link to="/contact">
                    <span className="link">Contact Us</span>
                  </Link>{" "}
                  |{" "}
                  <Link to="/join">
                    <span className="link">Join Us</span>
                  </Link>
                </div>
              </div>
            </div>
              
            <div className="col-md-3 news-dtl-col-md-3">
              <SideBarAdCard></SideBarAdCard>
              <RecentNews></RecentNews>
            </div>
              </>):(<>
              
              <div className="col-md-9 iframe-page">
                <iframe  id={"page-frame"+this.state.pagedata.id} name={"page-frame"+this.state.pagedata.id}  title={"page-frame"+this.state.pagedata.id} className="page-iframe full-screen" src={this.state.pagedata.targetUrl} type="text/html" placeholder_text={this.state.pagedata.description} loading="lazy"  sandbox="allow-scripts allow-same-origin" referrerPolicy={"no-referrer"}   frameBorder={0}  style={{border: "none",width: "100%",height: "100%" ,overflow:"hidden"}} scrolling="no" allow="fullscreen,payment" allowFullScreen={true} ></iframe>
              </div>

              <div className="col-md-3 ">
                <SideBarAdCard></SideBarAdCard>
                <RecentNews></RecentNews>
              </div>
              </>)
            }
            
            
          </div>
        </div>
      </>
    );
  }
}

export default NewsDetailController;
