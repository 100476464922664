import React, { Component } from "react";
import { InputGroup, Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
class FormFile extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) {
      return {
        ...props,
      };
  }
  render() {
    return (        
        <div className="input-field">
          <Form.Group className="mb-3" controlId={this.state.id}>
              <Form.Label>{this.state.title}</Form.Label>
              <InputGroup hasValidation>
              <Form.Control type={"hidden"} name={this.state.name} value={this.state.value??""} />
                <Form.Control type={"file"} name={this.state.name+"_file"} isInvalid={this.state.error?true:false}  onChange={this.state.handle} placeholder={this.state.placeholder} accept={this.state.accept} />
                <Form.Control.Feedback type="invalid">
                    {this.state.error}
                </Form.Control.Feedback>
              </InputGroup>
          </Form.Group> 
        </div>
    );
  }
}
export default FormFile;
