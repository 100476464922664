import { Link, Navigate } from "react-router-dom";
import logo from "./../images/logo.png";
import React, { Component } from "react";
import { AppContext } from "../config/common";
import Catalog from "../Catalog";
import { Form, Button, Alert } from "react-bootstrap";
import FormField from "../widgets/FormField";
import { SiteService } from "../services/site";

class ResetPasswordController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    const frmId = "frmForget" + new Date().getTime();
    this.state = {
      ...props,
      formId: frmId,
      formData: {
        code:props.code,
        password: null, 
        cpassword:null
      },
      errorData: {
        password: null, 
        cpassword:null
      },
      error: null,
      success: null,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
 
    let formData = this.state.formData;
    let errorData = {};
    let isInValid = false;
    
    let catelog=Catalog;
    for (const name in formData) {
      if(catelog.Strings.profile[name]){
        const {valid,message} =await catelog.Strings.profile[name].validate(formData[name]);      
        if (!valid) {
          errorData[name] = message;
          isInValid = true;
        }else if(name==="cpassword"){
          const {confirm,message}= catelog.Strings.profile['cpassword'].confirm(formData['cpassword'],formData['password'])
          if (!confirm) {
            errorData[name] = message;
            isInValid = true;
          }
        } 
      }
    } 
    let isSuccess = false;
    let isSubmit = isInValid ? false : true;
    let error ,success= null;
    this.setState({ errorData, isSubmit, isSuccess, error });
    if (!isInValid) {
    const newform={
        code:formData.code,
        password:formData.password,
        passwordConfirmation:formData.cpassword,
    }
      const res = await SiteService.resetPassword(newform);
      if (res) { 
        success = "Password reset successfully!";
        formData={};
        errorData={};
      }else{
        error = "Error resetting password. Please try again.";
      } 
      isSubmit = false;
    }
    this.setState({ errorData,formData, isSubmit, isSuccess, error,success });
  };
  handleChange =async (e) => {
    let catelog=Catalog;
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    this.setState({ formData }); 

    let errorData = {
      ...this.state.errorData,
      [name]: null,
    };
    if(catelog.Strings.profile[name]){
      const {valid,message} =await catelog.Strings.profile[name].validate(value);
      errorData[name]=valid?null:message;
    }
    this.setState({ errorData }); 
  };
  render() {
    const { settings } = this.context;
    return (
      <>
        <div className="forgotWrapp">
          {!Catalog.isLoad || settings.show_auth ? (
            <>
              {Catalog.Session.isLogined ? (
                <>
                  <Navigate replace to={Catalog.RediarectLogin} />
                </>
              ) : (
                <div className="row row-one forgotContainer">
                  <div className="col-md-4">
                    <div className="log-head">
                      <h3>Reset Password</h3>
                      <img src={logo} alt="logo" />
                    </div>
                    {this.state.error && (
                      <>
                        <Alert variant="danger" dismissible>
                          <p>{this.state.error}</p>
                        </Alert>
                      </>
                    )}

                    {this.state.success && (
                      <>
                        <Alert variant="success" dismissible>
                          <p>{this.state.success}</p>
                        </Alert>
                      </>
                    )}

                    <Form
                      id={this.state.formId}
                      className="forgotForm"
                      onSubmit={this.handleSubmit}
                      noValidate
                    > 

                      <FormField
                        id={this.state.formId + "password"}
                        title={"Enter new password"}
                        placeholder={"Enter new password"}
                        value={this.state.formData?.password}
                        error={this.state.errorData?.password}
                        name={"password"}
                        handle={this.handleChange}
                        type="password"
                      />
                      <FormField
                        id={this.state.formId + "cpassword"}
                        title={"Confirm new password"}
                        placeholder={"Confirm new password"}
                        value={this.state.formData?.cpassword}
                        error={this.state.errorData?.cpassword}
                        name={"cpassword"}
                        handle={this.handleChange}
                        type="password"
                      />
                      <div className="formfield">
                        <Button
                          variant="primary"
                          className="forgotBtn1"
                          type="submit"
                          disabled={this.state.isSubmit}
                        >
                          Password Reset
                        </Button>
                      </div>
                      <div className="forgotFooter">
                        <p>
                          Click Here to <Link to={"/login"}>Login</Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Navigate replace to="/404" />
            </>
          )}
        </div>
      </>
    );
  }
}
export default ResetPasswordController;
