import "./../css/Product.css"; 
import ProductCard from "./../widgets/ProductCard";

const Product = () => { 
    window.scrollTo(0, 0); 
    return (
        <div className="product-wrapp">
            <div className="row productContainer">
                <h3>Our Products</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam at ipsum pretium</p>
                <div className="productRow">
                    <ProductCard />
                    <ProductCard />
                    <ProductCard />
                    <ProductCard />
                    <ProductCard />
                    <ProductCard />
                    <ProductCard />
                    <ProductCard />
                </div>
            </div>
        </div>
    );
  };
  
  export default Product;
  