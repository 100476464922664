import { Component } from "react"; 
import { Link } from "react-router-dom";
import { titleSlug } from "../../config/common";

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <div className="store-item">
        <Link to={`/catalogs/firms/${this.state.storeItem.id}/${titleSlug(this.state.storeItem.Title)}`}>
            <div className="store-item-body">
              <div className="store-avathar">
                <img src={this.state.storeItem.image} alt={this.state.storeItem.Title} />
              </div>
              <div className="store-title">
                <span>{this.state.storeItem.Title}</span>
              </div>
              <div className="store-sub-title">
                <span>{this.state.storeItem.SubTitle}</span>
              </div>  
            </div>
        </Link>
      </div>
    );
  }
}
export default Card;
