export const StringsEn = {
  profile: {
    PrimaryImageId: {
      title: "Image",
      placeholder: "Image",
      name: "image",
      validate: async (value) => {
        //   const fieldName= StringsEn.profile.last_name.name
        //   if (!value) {
        //     return { valid: false, message: `The ${fieldName} field is required.` };
        //   }
        //   if (value.length < 3) {
        //     return { valid: false, message: `The ${fieldName} must be at least 3 characters long.` };
        //   }
        return { valid: true,  message: "" };
      },
    },
    SecondaryImageIds: {
      title: "Image",
      placeholder: "Image",
      name: "image",
      validate: async (value) => { 
        return { valid: true,  message: "" };
      },
    },
    Category:{      
      title: "Category",
      placeholder: "Category",
      name: "category",
      validate: async (value) => {
        const fieldName = StringsEn.profile.Property1.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length === 0) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 1 of list selected.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    SubCategory1:{      
      title: "Sub Category1",
      placeholder: "Sub Category1",
      name: "Sub Category1",
      validate: async (value) => { 
        return { valid: true,  message: "" };
      },
    },
    SubCategory2:{      
      title: "Sub Category2",
      placeholder: "Sub Category2",
      name: "Sub Category2",
      validate: async (value) => { 
        return { valid: true,  message: "" };
      },
    },
    Title: {
      title: "Name",
      placeholder: "Name",
      name: "name",
      validate: async (value) => {
        const fieldName = StringsEn.profile.Title.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length < 3) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 3 characters long.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    Identifier: {
      title: "License",
      placeholder: "License",
      name: "license",
      validate: async (value) => {
        const fieldName = StringsEn.profile.Identifier.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length < 3) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 3 characters long.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    DisplayDate: {
      title: "Practice Since",
      placeholder: "Practice Since",
      name: "practice since",
      validate: async (value) => {
        const fieldName = StringsEn.profile.DisplayDate.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (!datePattern.test(value)) {
          return { valid: false, message: `The ${fieldName} must be in the format YYYY-MM-DD.` };
        }
        const [year, month, day] = value.split('-').map(Number);
        const isValidDate = (year > 0 && month > 0 && month <= 12 && day > 0 && day <= 31);

        if (!isValidDate) {
          return { valid: false, message: `The ${fieldName} is not a valid date.` };
        }
        return { valid: true,  message: "" };
      },
    },
    SubTitle: {
      title: "Qualification",
      placeholder: "Qualification",
      name: "qualification",
      validate: async (value) => {
        const fieldName = StringsEn.profile.SubTitle.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length < 3) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 3 characters long.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    email_address: {
      title: "Email Address",
      placeholder: "Email Address",
      name: "email address",
      validate: async (value) => {
        const fieldName = StringsEn.profile.email_address.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be a valid email address.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    phone_number: {
      title: "Phone Number",
      placeholder: "Phone Number",
      name: "phone number",
      validate: async (value) => {
        const fieldName = StringsEn.profile.phone_number.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        const mobilePattern = /^\d{10}$/;
        if (!mobilePattern.test(value)) {
          return {
            valid: false,
            message: `The ${fieldName} must be a valid 10-digit number.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    address: {
      title: "Address",
      placeholder: "Address",
      name: "address",
      validate: async (value) => {
        const fieldName = StringsEn.profile.address.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length < 3) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 3 characters long.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    website_link: {
      title: "Website Link",
      placeholder: "Website Link",
      name: "website link",
      validate: async (value) => {
        const fieldName = StringsEn.profile.website_link.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/[\w-]*)*\/?$/;
        if (!urlPattern.test(value)) {
          return { valid: false, message: `The ${fieldName} must be a valid URL.` };
        }
        return { valid: true,  message: "" };
      },
    },
    Property1: {
      title: "State",
      placeholder: "State",
      name: "state",
      validate: async (value) => {
        const fieldName = StringsEn.profile.Property1.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length === 0) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 1 of list selected.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    Property2: {
      title: "City",
      placeholder: "City",
      name: "city",
      validate: async (value) => {
        const fieldName = StringsEn.profile.Property2.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length === 0) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 1 of list selected.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    PropertyList1: {
      title: "Specialization Area",
      placeholder: "Specialization Area",
      name: "specialization area",
      validate: async (value) => {
        const fieldName = StringsEn.profile.PropertyList1.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length === 0) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 1 of list selected.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    PropertyList2: {
      title: "Courts",
      placeholder: "Courts",
      name: "courts",
      validate: async (value) => {
        const fieldName = StringsEn.profile.PropertyList2.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length === 0) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 1 of list selected.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    PropertyList3: {
      title: "Spoken languages",
      placeholder: "Spoken languages",
      name: "spoken languages",
      validate: async (value) => {
        const fieldName = StringsEn.profile.PropertyList3.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length === 0) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 1 of list selected.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    Detail1: {
      title: "Profile detail",
      placeholder: "Profile detail",
      name: "profile detail",
      validate: async (value) => {
        const fieldName = StringsEn.profile.Detail1.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        }
        if (value.length < 3) {
          return {
            valid: false,
            
            message: `The ${fieldName} must be at least 3 characters long.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    Price: {
      title: "Hourly price",
      placeholder: "Hourly price",
      name: "Hourly price",
      validate: async (value) => {
        const fieldName = StringsEn.profile.Detail1.name;
        if (!value) {
          return {
            valid: false,
            
            message: `The ${fieldName} field is required.`,
          };
        } 
        return { valid: true,  message: "" };
      },
    },
  },
};
