import "./../css/News.css"; 
import NewsController from "./../controllers/NewsController";
import { useParams } from 'react-router-dom';

const News = () => {
  const params = useParams();
  return <NewsController {...params} />;
};
  

  export default News;
  