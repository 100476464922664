import { Link, Navigate } from "react-router-dom";
import logo from "./../images/logo.png";
import React, { Component } from "react";
import { AppContext } from "../config/common";
import Catalog from "../Catalog";
import { Form, Button, Alert } from "react-bootstrap";
import FormField from "../widgets/FormField";
import { SiteService } from "../services/site";

class ForgotPasswordController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    const frmId = "frmForget" + new Date().getTime();
    this.state = {
      ...props,
      formId: frmId,
      formData: {
        email: null, 
      },
      errorData: {
        email: null, 
      },
      error: null,
      success: null,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
 
    let formData = this.state.formData;
    let errorData = {};
    let isInValid = false;
    for (const name in formData) {
      errorData[name] = "The " + name + " field is required";
      if (Object.hasOwnProperty.call(formData, name)) {
        if (formData[name] && formData[name] !== "") {
          errorData[name] = null;
        } else {
          isInValid = true;
        }
      } else {
        isInValid = true;
      }
    }
    let isSuccess = false;
    let isSubmit = isInValid ? false : true;
    let error ,success= null;
    this.setState({ errorData, isSubmit, isSuccess, error });
    if (!isInValid) {
      const res = await SiteService.forgetPassword(formData);
      if (res) { 
        success = "Password reset link sent successfully!";
        formData={};
        errorData={};
      }else{
        error = "Error sending password reset link.";
      } 
      isSubmit = false;
    }
    this.setState({ errorData,formData, isSubmit, isSuccess, error,success });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    this.setState({ formData });
    if (!value || value === "") {
      const errorData = {
        ...this.state.errorData,
        [name]: "The " + name + " field is required",
      };
      this.setState({ errorData });
    } else {
      const errorData = {
        ...this.state.errorData,
        [name]: null,
      };
      this.setState({ errorData });
    }
  };
  render() {
    const { settings } = this.context;
    return (
      <>
        <div className="forgotWrapp">
          {!Catalog.isLoad || settings.show_auth ? (
            <>
              {Catalog.Session.isLogined ? (
                <>
                  <Navigate replace to={Catalog.RediarectLogin} />
                </>
              ) : (
                <div className="row row-one forgotContainer">
                  <div className="col-md-4">
                    <div className="log-head">
                      <h3>Forgot</h3>
                      <img src={logo} alt="logo" />
                    </div>
                    {this.state.error && (
                      <>
                        <Alert variant="danger" dismissible>
                          <p>{this.state.error}</p>
                        </Alert>
                      </>
                    )}

                    {this.state.success && (
                      <>
                        <Alert variant="success" dismissible>
                          <p>{this.state.success}</p>
                        </Alert>
                      </>
                    )}

                    <Form
                      id={this.state.formId}
                      className="forgotForm"
                      onSubmit={this.handleSubmit}
                      noValidate
                    >
                      <p>
                        Enter your email address to receive a password reset
                        link.
                      </p>

                      <FormField
                        id={this.state.formId + "email"}
                        placeholder={"Your Email"}
                        value={this.state.formData?.email}
                        error={this.state.errorData?.email}
                        name={"email"}
                        handle={this.handleChange}
                      />
                      <div className="formfield">
                        <Button
                          variant="primary"
                          className="forgotBtn1"
                          type="submit"
                          disabled={this.state.isSubmit}
                        >
                          Send Password Reset Link
                        </Button>
                      </div>
                      <div className="forgotFooter">
                        <p>
                          Click Here to <Link to={"/login"}>Login</Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Navigate replace to="/404" />
            </>
          )}
        </div>
      </>
    );
  }
}
export default ForgotPasswordController;
