
import { request } from "./../config/request"
import { API_ENDPOINT } from "./../config/constant"


export const AdsService = {

    async fetchInnerContentAdd() {
        const ads=await request.get('/api/site-ads?populate=*&filters[type][$eq]=Inner Content').then((response)=>{
            let proData=[];
            if(response.status===200){
                for (let pro in response.data.data) {                                             
                    proData.push({
                        id:response.data.data[pro].id,
                        title:response.data.data[pro].attributes.title,
                        youtube:response.data.data[pro].attributes.youtube,
                        link:response.data.data[pro].attributes.link,
                        image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                    })
                }
            }
            return proData;
        }).catch ((error) =>{ 
            return [];
        })        
        return ads;
    },
    async fetchFullWidthAdd() {
        const ads=await request.get('/api/site-ads?populate=*&filters[type][$eq]=Two Column into List').then((response)=>{
            let proData=[];
            if(response.status===200){
                for (let pro in response.data.data) {                                             
                    proData.push({
                        id:response.data.data[pro].id,
                        title:response.data.data[pro].attributes.title,
                        youtube:response.data.data[pro].attributes.youtube,
                        link:response.data.data[pro].attributes.link,
                        image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                    })
                }
            }
            return proData;
        }).catch ((error) =>{ 
            return [];
        })        
        return ads;
    },
   
    async fetchAds() {        
        const ads=await request.get('/api/site-ads?populate=*').then((response)=>{
            let proData={
                sidebar:[],
                onecol:[],
                twocol:[]
            };
            if(response.status===200){
                for (let pro in response.data.data) {
                    switch (response.data.data[pro].attributes.type) {
                        case "Side Bar":                            
                                proData.sidebar.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;                        
                        case "One Column into List":                            
                                proData.onecol.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                                                      
                        case "Two Column into List":                            
                                proData.twocol.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                    
                        default:
                                                        
                                proData.sidebar.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                    }
                }
            }
            return proData;
        }).catch ((error) =>{
            return {
                sidebar:[],
                onecol:[],
                twocol:[]
            };
        })        
        return ads;
    },
    async fetchSidebarAds() {        
        const ads=await request.get('/api/site-ads?populate=*&filters[type][$eq]=Side Bar').then((response)=>{
            let proData=[];
            if(response.status===200){
                for (let pro in response.data.data) {                                             
                    proData.push({
                        id:response.data.data[pro].id,
                        title:response.data.data[pro].attributes.title,
                        youtube:response.data.data[pro].attributes.youtube,
                        link:response.data.data[pro].attributes.link,
                        image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                    })
                }
            }
            return proData;
        }).catch ((error) =>{ 
            return [];
        })        
        return ads;
    },
}