import React, { Component } from "react";
import logo from "./../images/logo.png";
import { Link, Navigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import { AppContext } from "./../config/common";
import FormField from "../widgets/FormField";
import { SiteService } from "../services/site";
import FormFile from "../widgets/FormFile";
import FormRadioGroup from "../widgets/FormRadioGroup";
import Catalog from "./../Catalog";
import FormDatePicker from "../widgets/FormDatePicker";
import FormDropdown from "../widgets/FormDropdown";
import FormTextArea from "../widgets/FormTextArea";

class RegisterController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    const frmId = "frmReg" + new Date().getTime();
    this.state = {
      ...props,
      formId: frmId,
      acceptLegalAgreement:false,
      formData: {
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        mobile: null,
        password: null,
        cpassword: null,
        role: "4", 
      },
      formSecondData: {
        PrimaryImageId: null,
        Title: null,
        Identifier: null,
        DisplayDate: null,
        SubTitle: null,
        contact: {
          phone_number: null,
          email_address: null,
          address: null,
          website_link: null,
        },
        Property1: [],
        Property2: [],
        PropertyList1: [],
        PropertyList2: [],
        PropertyList3: [],
        Detail1: null,
      },
      errorData: {
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        mobile: null,
        password: null,
        cpassword: null,

        PrimaryImageId: null,
        Title: null,
        Identifier: null,
        DisplayDate: null,
        SubTitle: null,
        Property1: null,
        Property2: null,
        PropertyList1: null,
        PropertyList2: null,
        PropertyList3: null,
        Detail1: null,

        phone_number: null,
        email_address: null,
        address: null,
        website_link: null,
      },
      secondform: false,
      isSubmit: false,
      isSuccess: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { settings } = this.context;
    let formData = this.state.formData;
    let formSecondData = this.state.formSecondData;
    let secondform = this.state.secondform;
    let errorData = {};
    let isInValid = false;

    let catelog = Catalog;
    for (const name in formData) {
      if (catelog.Strings.profile[name]) {
        const { valid, message } = await catelog.Strings.profile[name].validate(
          formData[name]
        );
        if (!valid) {
          errorData[name] = message;
          isInValid = true;
        } else if (name === "cpassword") {
          const { confirm, message } = catelog.Strings.profile[
            "cpassword"
          ].confirm(formData["cpassword"], formData["password"]);
          if (!confirm) {
            errorData[name] = message;
            isInValid = true;
          }
        }
      }
    }
    let isSuccess = false;
    let isSubmit = isInValid ? false : true;
    this.setState({ errorData, isSubmit, isSuccess });
    if (!isInValid) {
      formData.username =
        (formData.first_name || "") +
        "-" +
        (formData.last_name || "") +
        "-" +
        new Date().getTime().toString();
      if (formData.role === "4") {
        const { valid, message } = await Catalog.Strings.profile.accept_legal_agreement.validate(this.state.acceptLegalAgreement);
        if(valid){
          const newForm = {
            ...formData,
            role: parseInt(formData.role),
            accept_legal_agreement:true,
          };
          const res = await SiteService.registerUser(newForm, settings);
          if (res.id) {
            Catalog.Message="Registraion Success";
            window.scrollTo(0, 0); 
            formData = {
              first_name: null,
              last_name: null,
              name: null,
              email: null,
              mobile: null,
              password: null,
              cpassword: null,
              role: "4", 
            };
            isSuccess = true;
          }
        }else{
          isInValid=true;
          errorData.accept_legal_agreement = message;
        }
        
      } else {
        if (secondform) {
          const roles = {
            4: "Basic",
            5: "Lawyer",
            6: "Firm",
            7: "Judge",
          };
          switch (roles[formData.role]) {
            case "Lawyer":
              catelog = Catalog.Lawyer;
              break;
            case "Firm":
              catelog = Catalog.Firm;
              break;
            default:
              break;
          }
          for (const name in formSecondData) {
            if (name === "contact") {
              for (const cname in formSecondData.contact) {
                if (catelog.Strings.profile[cname]) {
                  const { valid, message } = await catelog.Strings.profile[
                    cname
                  ].validate(formSecondData.contact[cname]);
                  if (!valid) {
                    errorData[cname] = message;
                    isInValid = true;
                  }
                }
              }
            } else {
              if (catelog.Strings.profile[name]) {
                const { valid, message } = await catelog.Strings.profile[
                  name
                ].validate(formSecondData[name]);
                if (!valid) {
                  errorData[name] = message;
                  isInValid = true;
                }
              }
            }
          }
          isSuccess = false;
          isSubmit = isInValid ? false : true;
          this.setState({ errorData, isSubmit, isSuccess });
          if (!isInValid) {
            const { valid, message } = await Catalog.Strings.profile.accept_legal_agreement.validate(this.state.acceptLegalAgreement);
            if(valid){

              const newForm = {
                ...formData,
                role: parseInt(formData.role),
                accept_legal_agreement:true,
              };
              const res = await SiteService.registerUser(newForm, settings);
              if (res.id) {
                 
                formSecondData.users_permissions_user = res.id;
                // if (formData.role === "5") {
                formSecondData.SubCategory2 = formData.role === "5" ? 0 : 1;
                await SiteService.registerLawyer(formSecondData);
                Catalog.Message="Registraion Success";
                window.scrollTo(0, 0); 
                // }
                // if (formData.role === "6") {
                //   await SiteService.registerFirm(formSecondData, settings);
                // }
                formData = {
                  first_name: null,
                  last_name: null,
                  name: null,
                  email: null,
                  mobile: null,
                  password: null,
                  cpassword: null,
                  role: "4", 
                };
                isSuccess = true;
                secondform = false; 
                formSecondData = {
                  PrimaryImageId: null,
                  Title: null,
                  Identifier: null,
                  DisplayDate: null,
                  SubTitle: null,
                  contact: {
                    phone_number: null,
                    email_address: null,
                    address: null,
                    website_link: null,
                  },
                  Property1: [],
                  Property2: [],
                  PropertyList1: [],
                  PropertyList2: [],
                  PropertyList3: [],
                  Detail1: null,
                };
              } else { 
              }
            }else{
              isInValid=true;
              errorData.accept_legal_agreement = message;
            }
          }
        } else {
          secondform = true;
        }
      }
      isSubmit = false;
      this.setState({
        formData,
        isSubmit,
        isSuccess,
        formSecondData,
        secondform,
      });
    }
    return !isInValid;
  };
  resetForm = async () => {
    const secondform = false;
    this.setState({ secondform });
  };
  handleFileChange = async (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      let formData = new FormData();
      formData.append("files", files[0]);
      let uploads = await SiteService.uploadMedia(formData);
      if (uploads.length > 0) {
        const formSecondData = {
          ...this.state.formSecondData,
          PrimaryImageId: uploads[0].id,
        };
        this.setState({ formSecondData });
      }
    }
  };
  handleSecondChange = async (e) => {
    const roles = {
      4: "Basic",
      5: "Lawyer",
      6: "Firm",
      7: "Judge",
    };
    let catelog = Catalog;
    switch (roles[this.state.formData.role]) {
      case "Lawyer":
        catelog = Catalog.Lawyer;
        break;
      case "Firm":
        catelog = Catalog.Firm;
        break;
      default:
        break;
    }
    const { name, value } = e.target;
    const formSecondData = {
      ...this.state.formSecondData,
      [name]: value,
    };
    this.setState({ formSecondData });

    let errorData = {
      ...this.state.errorData,
      [name]: null,
    };
    if (catelog.Strings.profile[name]) {
      const { valid, message } = await catelog.Strings.profile[name].validate(
        value
      );
      errorData[name] = valid ? null : message;
    }
    this.setState({ errorData });
  };

  handleContactChange = async (e) => {
    const roles = {
      4: "Basic",
      5: "Lawyer",
      6: "Firm",
      7: "Judge",
    };
    let catelog = Catalog;
    switch (roles[this.state.formData.role]) {
      case "Lawyer":
        catelog = Catalog.Lawyer;
        break;
      case "Firm":
        catelog = Catalog.Firm;
        break;
      default:
        break;
    }
    const { name, value } = e.target;
    let formSecondData = this.state.formSecondData;
    formSecondData.contact[name] = value;
    this.setState({ formSecondData });

    let errorData = {
      ...this.state.errorData,
      [name]: null,
    };
    if (catelog.Strings.profile[name]) {
      const { valid, message } = await catelog.Strings.profile[name].validate(
        value
      );
      errorData[name] = valid ? null : message;
    }
    this.setState({ errorData });
  };

  handleChange = async (e) => {
    let catelog = Catalog;
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    this.setState({ formData });

    let errorData = {
      ...this.state.errorData,
      [name]: null,
    };
    if (catelog.Strings.profile[name]) {
      const { valid, message } = await catelog.Strings.profile[name].validate(
        value
      );
      errorData[name] = valid ? null : message;
    }
    this.setState({ errorData });
  };
  render() {
    const { settings } = this.context;
    return (
      <>
        <div className="signInWrapp">
          {this.state.isSuccess === true && (
            <>
              <Navigate replace to={"/login"} />
            </>
          )}

          {!Catalog.isLoad || settings.show_auth ? (
            <>
              {Catalog.Session.isLogined ? (
                <>
                  <Navigate replace to="/account" />
                </>
              ) : (
                <div className="signInWrapp">
                  <div className="row row-one signInContainer">
                    <div className="col-md-4">
                      <div className="signIn-head">
                        <h3>Register</h3>
                        <img src={logo} alt="logo" />
                      </div>
                      {this.state.secondform ? (
                        <>
                          <FormTwoController
                            role={this.state.formData.role}
                            formId={this.state.formId}
                            resetForm={this.resetForm}
                            handleSubmit={this.handleSubmit}
                            handleContactChange={this.handleContactChange}
                            handleChange={this.handleSecondChange}
                            handleFileChange={this.handleFileChange}
                            isSubmit={this.state.isSubmit}
                            formData={this.state.formSecondData}
                            errorData={this.state.errorData}
                            acceptLegalAgreement={this.state.acceptLegalAgreement}
                            accept = {(e)=>{
                              const acceptLegalAgreement= e.target.checked 
                              this.setState({acceptLegalAgreement}) 
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <FormOneController
                            formId={this.state.formId}
                            resetForm={this.resetForm}
                            handleSubmit={this.handleSubmit}
                            handleChange={this.handleChange}
                            isSubmit={this.state.isSubmit}
                            formData={this.state.formData}
                            errorData={this.state.errorData}
                            acceptLegalAgreement={this.state.acceptLegalAgreement}
                            accept = {(e)=>{
                              const acceptLegalAgreement= e.target.checked 
                              this.setState({acceptLegalAgreement}) 
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Navigate replace to="/404" />
            </>
          )}
        </div>
      </>
    );
  }
}
export default RegisterController;

class FormOneController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      catelog: Catalog,
      roles: [
        { value: "4", label: "Basic User" },
        { value: "5", label: "Register as an induvidual lawyer" },
        { value: "6", label: "Register as legal firm" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  render() {
    return (
      <Form
        id={this.state.formId}
        className="signInForm"
        onSubmit={this.state.handleSubmit}
        noValidate
      >
        <FormField
          id={this.state.formId + "firstName"}
          title={this.state.catelog.Strings.profile.first_name.title}
          placeholder={
            this.state.catelog.Strings.profile.first_name.placeholder
          }
          value={this.state.formData?.first_name}
          error={this.state.errorData?.first_name}
          name={"first_name"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "lastName"}
          title={this.state.catelog.Strings.profile.last_name.title}
          placeholder={this.state.catelog.Strings.profile.last_name.placeholder}
          value={this.state.formData?.last_name}
          error={this.state.errorData?.last_name}
          name={"last_name"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "email"}
          type={"email"}
          title={this.state.catelog.Strings.profile.email.title}
          placeholder={this.state.catelog.Strings.profile.email.placeholder}
          value={this.state.formData?.email}
          error={this.state.errorData?.email}
          name={"email"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "mobile"}
          title={this.state.catelog.Strings.profile.mobile.title}
          placeholder={this.state.catelog.Strings.profile.mobile.placeholder}
          value={this.state.formData?.mobile}
          error={this.state.errorData?.mobile}
          name={"mobile"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "password"}
          type={"password"}
          title={this.state.catelog.Strings.profile.password.title}
          placeholder={this.state.catelog.Strings.profile.password.placeholder}
          value={this.state.formData?.password}
          error={this.state.errorData?.password}
          name={"password"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "repassword"}
          type={"password"}
          title={this.state.catelog.Strings.profile.cpassword.title}
          placeholder={this.state.catelog.Strings.profile.cpassword.placeholder}
          value={this.state.formData?.cpassword}
          error={this.state.errorData?.cpassword}
          name={"cpassword"}
          handle={this.state.handleChange}
        />
        <FormRadioGroup
          id={this.state.formId + "role"}
          type={"radio"}
          value={this.state.formData?.role}
          error={this.state.errorData?.role}
          name={"role"}
          handle={this.state.handleChange}
          options={this.state.roles}
        />
        {(this.state.formData?.role??"4")==="4"&&(<div className="formfield">
          <div className="input-field">
            <Form.Group
              className="mb-3"
              controlId={this.state.formId + "accept_legal_agreement"}
            >
              <Form.Check
                id={this.state.formId + "accept_legal_agreement"}
                type={"checkbox"}
                label={
                  Catalog.Strings.profile.accept_legal_agreement
                    .title
                }
                name={"accept_legal_agreement"}
                value={'accept legal agreement'}
                checked={this.state.acceptLegalAgreement}
                onChange={this.state.accept}
                isInvalid={
                  this.state.errorData?.accept_legal_agreement ? true : false
                }
              />

              <Form.Control.Feedback type="invalid">
                {this.state.errorData?.accept_legal_agreement}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>)}
        <div className="formfield">
          <Button
            variant="primary"
            className={"signInBtn1"}
            type="submit"
            disabled={this.state.isSubmit}
          >
            {(this.state.formData?.role ?? "4") === "4" ? "Register" : "Next"}
          </Button>
        </div>
        <div className="signInFooter">
          <p>
            Already have an account? <Link to={"/login"}>Login</Link>
          </p>
        </div>
      </Form>
    );
  }
}
class FormTwoController extends Component {
  constructor(props) {
    super(props);
    switch (
      {
        4: "Basic",
        5: "Lawyer",
        6: "Firm",
        7: "Judge",
      }[props.role]
    ) {
      case "Lawyer":
        this.state = {
          catelog: Catalog.Lawyer,
          ...props,
        };
        break;

      case "Firm":
        this.state = {
          catelog: Catalog.Firm,
          ...props,
        };
        break;
      default:
        this.state = {
          catelog: Catalog,
          ...props,
        };
        break;
    }
  }

  static getDerivedStateFromProps(props, state) {
    switch (
      {
        4: "Basic",
        5: "Lawyer",
        6: "Firm",
        7: "Judge",
      }[props.role]
    ) {
      case "Lawyer":
        return {
          catelog: Catalog.Lawyer,
          ...props,
        };

      case "Firm":
        return {
          catelog: Catalog.Firm,
          ...props,
        };
      default:
        return {
          catelog: Catalog,
          ...props,
        };
    }
  }
  render() {
    return (
      <Form
        id={this.state.formId}
        className="signInForm"
        onSubmit={this.state.handleSubmit}
        noValidate
      >
        <FormFile
          id={this.state.formId + "image"}
          type="file"
          title={this.state.catelog.Strings.profile.PrimaryImageId.title}
          placeholder={
            this.state.catelog.Strings.profile.PrimaryImageId.placeholder
          }
          value={this.state.formData?.PrimaryImageId}
          error={this.state.errorData?.PrimaryImageId}
          name={"PrimaryImageId"}
          handle={this.state.handleFileChange}
          accept={".png,.jpeg,.jpg,.gif"}
        />
        <FormField
          id={this.state.formId + "title"}
          title={this.state.catelog.Strings.profile.Title.title}
          placeholder={this.state.catelog.Strings.profile.Title.placeholder}
          value={this.state.formData?.Title}
          error={this.state.errorData?.Title}
          name={"Title"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "Identifier"}
          title={this.state.catelog.Strings.profile.Identifier.title}
          placeholder={
            this.state.catelog.Strings.profile.Identifier.placeholder
          }
          value={this.state.formData?.Identifier}
          error={this.state.errorData?.Identifier}
          name={"Identifier"}
          handle={this.state.handleChange}
        />
        <FormDatePicker
          id={this.state.formId + "displaydate"}
          title={this.state.catelog.Strings.profile.DisplayDate.title}
          placeholder={
            this.state.catelog.Strings.profile.DisplayDate.placeholder
          }
          value={this.state.formData?.DisplayDate}
          error={this.state.errorData?.DisplayDate}
          name={"DisplayDate"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "subtitle"}
          title={this.state.catelog.Strings.profile.SubTitle.title}
          placeholder={this.state.catelog.Strings.profile.SubTitle.placeholder}
          value={this.state.formData?.SubTitle}
          error={this.state.errorData?.SubTitle}
          name={"SubTitle"}
          handle={this.state.handleChange}
        />
        <FormField
          id={this.state.formId + "professionalemail"}
          title={this.state.catelog.Strings.profile.email_address.title}
          placeholder={
            this.state.catelog.Strings.profile.email_address.placeholder
          }
          type={"email"}
          value={this.state.formData?.contact?.email_address}
          error={this.state.errorData?.email_address}
          name={"email_address"}
          handle={this.state.handleContactChange}
        />
        <FormField
          id={this.state.formId + "professionalphone"}
          title={this.state.catelog.Strings.profile.phone_number.title}
          placeholder={
            this.state.catelog.Strings.profile.phone_number.placeholder
          }
          value={this.state.formData?.contact?.phone_number}
          error={this.state.errorData?.phone_number}
          name={"phone_number"}
          handle={this.state.handleContactChange}
        />
        <FormField
          id={this.state.formId + "address"}
          title={this.state.catelog.Strings.profile.address.title}
          placeholder={this.state.catelog.Strings.profile.address.placeholder}
          value={this.state.formData?.contact?.address}
          error={this.state.errorData?.address}
          name={"address"}
          handle={this.state.handleContactChange}
        />
        <FormField
          id={this.state.formId + "websitelink"}
          title={this.state.catelog.Strings.profile.website_link.title}
          placeholder={
            this.state.catelog.Strings.profile.website_link.placeholder
          }
          value={this.state.formData?.contact?.website_link}
          error={this.state.errorData?.website_link}
          name={"website_link"}
          handle={this.state.handleContactChange}
        />
        <FormDropdown
          id={this.state.formId + "state"}
          title={this.state.catelog.Strings.profile.Property1.title}
          placeholder={this.state.catelog.Strings.profile.Property1.placeholder}
          value={this.state.formData?.Property1}
          error={this.state.errorData?.Property1}
          options={this.state.catelog.Config.Property1}
          name={"Property1"}
          handle={this.state.handleChange}
          multiple={true}
        />
        <FormDropdown
          id={this.state.formId + "city"}
          title={this.state.catelog.Strings.profile.Property2.title}
          placeholder={this.state.catelog.Strings.profile.Property2.placeholder}
          value={this.state.formData?.Property2}
          error={this.state.errorData?.Property2}
          options={this.state.catelog.Config.Property2}
          name={"Property2"}
          handle={this.state.handleChange}
          multiple={true}
        />
        <FormDropdown
          id={this.state.formId + "specializationareas"}
          title={this.state.catelog.Strings.profile.PropertyList1.title}
          placeholder={
            this.state.catelog.Strings.profile.PropertyList1.placeholder
          }
          value={this.state.formData?.PropertyList1}
          error={this.state.errorData?.PropertyList1}
          options={this.state.catelog.Config.PropertyList1}
          name={"PropertyList1"}
          handle={this.state.handleChange}
          multiple={true}
        />
        <FormDropdown
          id={this.state.formId + "courts"}
          title={this.state.catelog.Strings.profile.PropertyList2.title}
          placeholder={
            this.state.catelog.Strings.profile.PropertyList2.placeholder
          }
          value={this.state.formData?.PropertyList2}
          error={this.state.errorData?.PropertyList2}
          options={this.state.catelog.Config.PropertyList2}
          name={"PropertyList2"}
          handle={this.state.handleChange}
          multiple={true}
        />
        <FormDropdown
          id={this.state.formId + "propertylist3"}
          title={this.state.catelog.Strings.profile.PropertyList3.title}
          placeholder={
            this.state.catelog.Strings.profile.PropertyList3.placeholder
          }
          value={this.state.formData?.PropertyList3}
          error={this.state.errorData?.PropertyList3}
          options={this.state.catelog.Config.PropertyList3}
          name={"PropertyList3"}
          handle={this.state.handleChange}
          multiple={true}
        />
        <FormTextArea
          id={this.state.formId + "profiledetail"}
          title={this.state.catelog.Strings.profile.Detail1.title}
          placeholder={this.state.catelog.Strings.profile.Detail1.placeholder}
          value={this.state.formData?.Detail1}
          error={this.state.errorData?.Detail1}
          name={"Detail1"}
          handle={this.state.handleChange}
        />

        <div className="formfield">
          <div className="input-field">
            <Form.Group
              className="mb-3"
              controlId={this.state.formId + "accept_legal_agreement"}
            >
              <Form.Check
                id={this.state.formId + "accept_legal_agreement"}
                type={"checkbox"}
                label={
                  Catalog.Strings.profile.accept_legal_agreement
                    .title
                }
                name={"accept_legal_agreement"}
                value={'accept legal agreement'}
                checked={this.state.acceptLegalAgreement}
                onChange={this.state.accept}
                isInvalid={
                  this.state.errorData?.accept_legal_agreement ? true : false
                }
              />

              <Form.Control.Feedback type="invalid">
                {this.state.errorData?.accept_legal_agreement}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="formfield">
          <Button
            variant="outline-primary"
            className="signInBtn2"
            type="reset"
            onClick={this.state.resetForm}
            disabled={this.state.isSubmit}
          >
            Back
          </Button>
          <Button
            variant="primary"
            className="signInBtn1"
            type="submit"
            disabled={this.state.isSubmit}
          >
            Register
          </Button>
        </div>
        <div className="signInFooter">
          <p>
            Already have an account? <Link to={"/login"}>Login</Link>
          </p>
        </div>
      </Form>
    );
  }
}
