const Config = {
  Category: [
  ],
  SubCategory1: [], 
  SubCategory2: [
    {
      label: "Lawyer",
      value: 5
    },
    {
      label: "Firm",
      value: 6
    },
  ],
  Property1: [
    {
      label: "Kerala",
      value: "Kerala"
    }, 
    {
      label: "Karnataka",
      value: "Karnataka"
    }, 
  ],
  Property2: [
    {
      label: "Kochi",
      value: "Kochi"
    },
    {
      label: "Kottayam",
      value: "Kottayam"
    },
    {
      label: "Bangalore",
      value: "Bangalore"
    }, 
  ],
  PropertyList1: [
    {
      label: "Criminal",
      value: "Criminal"
    }, 
    {
      label: "Finance",
      value: "Finance"
    }, 
  ],
  PropertyList2: [
    {
      label: "The High Court of Kerala",
      value: "The High Court of Kerala"
    }, 
    {
      label: "The High Court of Karnataka",
      value: "The High Court of Karnataka"
    }, 
    {
      label: "District Court, Ernakulam",
      value: "District Court, Ernakulam"
    }, 
    {
      label: "District Court, Kottayam",
      value: "District Court, Kottayam"
    }, 
  ],
  PropertyList3: [
    {
      label: "English",
      value: "English"
    }, 
    {
      label: "Hindi",
      value: "Hindi"
    }, 
  ],
};
export default Config;
