import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";

import { dateFormat } from "./../config/common";

import "./../css/widget/LatestNewsCard.css";
import noimage from "./../images/dailytime-noimg.png";

class LatestNewsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
      tag: props.tag,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news: props.news,
      tag: props.tag,
    };
  }
  render() {
    return (
      <>
        <div className="latest-wrap  ">
          <span className="latest-txt">LATEST NEWS</span>
          {this.state.news && this.state.news[0] ? (
            <Link to={"/" + this.state.news[0].slug}>
            <Card className="latest-block">
              <Card.Body>
                <div>
                  <span>{this.state.news[0].title}</span>
                  <div className="latest-img">
                    <img
                      src={
                        this.state.news[0].image
                          ? this.state.news[0].image
                          : noimage
                      }
                      alt={this.state.news[0].title}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Link>
          ) : (
            <></>
          )}
          {this.state.news && this.state.news[1] ? (
            <Link to={"/" + this.state.news[1].slug}>
              <Card className="latest-block">
                <Card.Body>
                  <span>{this.state.news[1].title}</span>
                  <div className="latest-img">
                    <img
                      src={
                        this.state.news[1].image
                          ? this.state.news[1].image
                          : noimage
                      }
                      alt={this.state.news[1].title}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Link>
          ) : (
            <></>
          )}
        </div>
        <div className="news-list">
          {this.state.news && this.state.news[2] ? (
            <Link to={"/" + this.state.news[2].slug}>
              <Card className="news-list-block">
                <Card.Body>
                  <div className="news-list-top">
                    {/* <span className="list-top-lft">The Times of India</span> */}
                    <span className="list-top-rgt">{dateFormat(this.state.news[2].publish_date,"M d, Y")}</span>
                  </div>
                  <h2>{this.state.news[2].title}</h2>
                  <div className="content"
                    dangerouslySetInnerHTML={{
                      __html: this.state.news[2].content,
                    }}
                  ></div>
                  <div className="news-list-bottom">
                    <span className="list-btm-lft">
                    <span className="viewcount" >{this.state.news[2].views_count}</span>
                      {/* <span className="like">55</span>
                      <span className="dislike"></span> */}
                    </span>
                    <span className="list-btm-rgt"> </span>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          ) : (
            <></>
          )}

          {this.state.news && this.state.news[3] ? (
            <Link to={"/" + this.state.news[3].slug}>
              <Card className="news-list-block">
                <Card.Body>
                  <div className="news-list-top">
                    {/* <span className="list-top-lft">The Times of India</span> */}
                    <span className="list-top-rgt">{dateFormat(this.state.news[3].publish_date,"M d, Y")}</span>
                  </div>
                  <h2>{this.state.news[3].title}</h2>
                  <div className="content"
                    dangerouslySetInnerHTML={{
                      __html: this.state.news[3].content,
                    }}
                  ></div>
                  <div className="news-list-bottom">
                    <span className="list-btm-lft">
                        <span className="viewcount" >{this.state.news[3].views_count}</span>
                      {/* <span className="like">55</span>
                      <span className="dislike"></span> */}
                    </span>
                    <span className="list-btm-rgt"> </span>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          ) : (
            <></>
          )}
          <Link to="/news/all" className="seemore">
            {"See More"}
          </Link>
        </div>
      </>
    );
  }
}

export default LatestNewsCard;
