import { request } from "./../config/request"
import { API_ENDPOINT } from "./../config/constant"


export const BookService = {

    async fetchAuthors() {
        const authors=await request.get('/api/books?fields=SubTitle').then((response)=>{
            let author=[];
            if(response.status===200){
                author=response.data.data.map(post => post.attributes.SubTitle);
                author=[...new Set(author)];
                author=author.map(name=> { return { label:name,value:name } })
            }
            return author;
        }).catch ((error) =>{ 
            return [];
        })        
        return authors;
    },
}