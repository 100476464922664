export const StringsHi={
    profile: {
      PrimaryImageId: {
        title: "छवि",
        placeholder: "छवि",
        name: "छवि",
        validate: async (value) => {
          //   const fieldName= StringsHi.profile.last_name.name
          //   if (!value) {
          //     return { valid: false, message: `${fieldName} फ़ील्ड आवश्यक है.` };
          //   }
          //   if (value.length < 3) {
          //     return { valid: false, message: `${fieldName} कम से कम 3 अक्षर लंबा होना चाहिए.` };
          //   }
          return { valid: true,  message: "" };
        },
      },
      SecondaryImageIds: {
        title: "Image",
        placeholder: "Image",
        name: "image",
        validate: async (value) => { 
          return { valid: true,  message: "" };
        },
      },
      Category:{      
        title: "Category",
        placeholder: "Category",
        name: "category",
        validate: async (value) => {
          const fieldName = StringsHi.profile.Property1.name;
          if (!value) {
            return {
              valid: false,
              
              message: `The ${fieldName} field is required.`,
            };
          }
          if (value.length === 0) {
            return {
              valid: false,
              
              message: `The ${fieldName} must be at least 1 of list selected.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      SubCategory1:{      
        title: "Sub Category1",
        placeholder: "Sub Category1",
        name: "Sub Category1",
        validate: async (value) => {
          const fieldName = StringsHi.profile.SubCategory1.name;
          if (!value) {
            return {
              valid: false,
              
              message: `The ${fieldName} field is required.`,
            };
          }
          if (value.length === 0) {
            return {
              valid: false,
              
              message: `The ${fieldName} must be at least 1 of list selected.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      SubCategory2:{      
        title: "Sub Category2",
        placeholder: "Sub Category2",
        name: "Sub Category2",
        validate: async (value) => {
          const fieldName = StringsHi.profile.SubCategory2.name;
          if (!value) {
            return {
              valid: false,
              
              message: `The ${fieldName} field is required.`,
            };
          }
          if (value.length === 0) {
            return {
              valid: false,
              
              message: `The ${fieldName} must be at least 1 of list selected.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      Title: {
        title: "नाम",
        placeholder: "नाम",
        name: "नाम",
        validate: async (value) => {
          const fieldName = StringsHi.profile.Title.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          if (value.length < 3) {
            return {
              valid: false,
              
              message: `${fieldName} कम से कम 3 अक्षर लंबा होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      Identifier: {
        title: "लाइसेंस",
        placeholder: "लाइसेंस",
        name: "लाइसेंस",
        validate: async (value) => {
          const fieldName = StringsHi.profile.Identifier.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          if (value.length < 3) {
            return {
              valid: false,
              
              message: `${fieldName} कम से कम 3 अक्षर लंबा होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      DisplayDate: {
        title: "तब से अभ्यास करें",
        placeholder: "तब से अभ्यास करें",
        name: "तब से अभ्यास करें",
        validate: async (value) => {
          const fieldName = StringsHi.profile.DisplayDate.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          const datePattern = /^\d{4}-\d{2}-\d{2}$/;
          if (!datePattern.test(value)) {
            return { valid: false, message: `${fieldName} YYYY-MM-DD प्रारूप में होना चाहिए` };
          }
          const [year, month, day] = value.split('-').map(Number);
          const isValidDate = (year > 0 && month > 0 && month <= 12 && day > 0 && day <= 31);
  
          if (!isValidDate) {
            return { valid: false, message: `${fieldName} मान्य दिनांक नहीं है` };
          }
          return { valid: true,  message: "" };
        },
      },
      SubTitle: {
        title: "योग्यता",
        placeholder: "योग्यता",
        name: "योग्यता",
        validate: async (value) => {
          const fieldName = StringsHi.profile.SubTitle.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          if (value.length < 3) {
            return {
              valid: false,
              
              message: `${fieldName} कम से कम 3 अक्षर लंबा होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      email_address: {
        title: "मेल पता",
        placeholder: "मेल पता",
        name: "मेल पता",
        validate: async (value) => {
          const fieldName = StringsHi.profile.email_address.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailPattern.test(value)) {
            return {
              valid: false,
              
              message: `${fieldName} एक वैध ईमेल पता होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      phone_number: {
        title: "फ़ोन नंबर",
        placeholder: "फ़ोन नंबर",
        name: "फ़ोन नंबर",
        validate: async (value) => {
          const fieldName = StringsHi.profile.phone_number.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          const mobilePattern = /^\d{10}$/;
          if (!mobilePattern.test(value)) {
            return {
              valid: false,
              message: `${fieldName} एक वैध 10-अंकीय संख्या होनी चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      address: {
        title: "पता",
        placeholder: "पता",
        name: "पता",
        validate: async (value) => {
          const fieldName = StringsHi.profile.address.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          if (value.length < 3) {
            return {
              valid: false,
              
              message: `${fieldName} कम से कम 3 अक्षर लंबा होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      website_link: {
        title: "वेबसाइट लिंक",
        placeholder: "वेबसाइट लिंक",
        name: "वेबसाइट लिंक",
        validate: async (value) => {
          const fieldName = StringsHi.profile.website_link.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/[\w-]*)*\/?$/;
          if (!urlPattern.test(value)) {
            return { valid: false, message: `${fieldName} एक वैध यूआरएल होना चाहिए` };
          }
          return { valid: true,  message: "" };
        },
      },
      Property1: {
        title: "राज्य",
        placeholder: "राज्य",
        name: "राज्य",
        validate: async (value) => {
          const fieldName = StringsHi.profile.Property1.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          if (value.length === 0) {
            return {
              valid: false,
              
              message: `${fieldName} चयनित सूची में से कम से कम 1 होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      Property2: {
        title: "शहर",
        placeholder: "शहर",
        name: "शहर",
        validate: async (value) => {
          const fieldName = StringsHi.profile.Property2.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          if (value.length === 0) {
            return {
              valid: false,
              
              message: `${fieldName} चयनित सूची में से कम से कम 1 होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      PropertyList1: {
        title: "विशेषज्ञता क्षेत्र",
        placeholder: "विशेषज्ञता क्षेत्र",
        name: "विशेषज्ञता क्षेत्र",
        validate: async (value) => {
          const fieldName = StringsHi.profile.PropertyList1.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          if (value.length === 0) {
            return {
              valid: false,
              
              message: `${fieldName} चयनित सूची में से कम से कम 1 होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      PropertyList2: {
        title: "अदालतें",
        placeholder: "अदालतें",
        name: "अदालतें",
        validate: async (value) => {
          const fieldName = StringsHi.profile.PropertyList2.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          if (value.length === 0) {
            return {
              valid: false,
              
              message: `${fieldName} चयनित सूची में से कम से कम 1 होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      PropertyList3: {
        title: "बोली जाने वाली भाषाएँ",
        placeholder: "बोली जाने वाली भाषाएँ",
        name: "बोली जाने वाली भाषाएँ",
        validate: async (value) => {
          const fieldName = StringsHi.profile.PropertyList3.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          if (value.length === 0) {
            return {
              valid: false,
              
              message: `${fieldName} चयनित सूची में से कम से कम 1 होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      Detail1: {
        title: "प्रोफ़ाइल विवरण",
        placeholder: "प्रोफ़ाइल विवरण",
        name: "प्रोफ़ाइल विवरण",
        validate: async (value) => {
          const fieldName = StringsHi.profile.Detail1.name;
          if (!value) {
            return {
              valid: false,
              
              message: `${fieldName} फ़ील्ड आवश्यक है.`,
            };
          }
          if (value.length < 3) {
            return {
              valid: false,
              
              message: `${fieldName} कम से कम 3 अक्षर लंबा होना चाहिए.`,
            };
          }
          return { valid: true,  message: "" };
        },
      },
      Price: {
        title: "Purchase price",
        placeholder: "Purchase price",
        name: "purchase price",
        validate: async (value) => {
          const fieldName = StringsHi.profile.Detail1.name;
          if (!value) {
            return {
              valid: false,
              
              message: `The ${fieldName} field is required.`,
            };
          } 
          return { valid: true,  message: "" };
        },
      },
    },
}