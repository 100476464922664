import React, { Component } from "react";
import FormField from "../widgets/FormField";
import RecentNews from "../widgets/RecentNews";
import SideBarAdCard from "../widgets/SideBarAdCard";
import { Button, Form, Alert } from "react-bootstrap";

import { SiteService } from "./../services/site";

class ComingSoonController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      categorys: [],
      formData: {
        email: null,
      },
      errorData: {
        email: null,
      },
      subscriptionerror: null,
      subscriptionsuccess: null,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = this.state.formData;
    let errorData = {};
    let isInValid = false;
    for (const name in formData) {
      errorData[name] = "The " + name + " field is required";
      if (Object.hasOwnProperty.call(formData, name)) {
        if (formData[name] && formData[name] !== "") {
          errorData[name] = null;
        } else {
          isInValid = true;
        }
      } else {
        isInValid = true;
      }
    }
    let subscriptionerror = null;
    this.setState({ errorData, subscriptionerror });
    if (!isInValid) {
      const subscription = await SiteService.subscribeComingSoon(formData);
      if (subscription) {
        formData = {
          email: null,
        };
        errorData = {
          email: null,
        };
        const subscriptionsuccess =
          "Thank you for subscribing. We look forward to staying in touch and bringing you great content!";
        this.setState({ formData, errorData, subscriptionsuccess });
      } else {
        if (subscription.error) {
          subscriptionerror = subscription.error.message;
          this.setState({ subscriptionerror });
        }
      }
    }
    return !isInValid;
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    this.setState({ formData });
    if (!value || value === "") {
      const errorData = {
        ...this.state.errorData,
        [name]: "The " + name + " field is required",
      };
      this.setState({ errorData });
    } else {
      const errorData = {
        ...this.state.errorData,
        [name]: null,
      };
      this.setState({ errorData });
    }
  };
  render() {
    return (
      <div className="home-page-outer">
        <div className="row row-two">
          <div className="col-md-9">
            <div className="contact-wrap">
              <div className="contact-row row">
                <div className="contact-col col-md-7 mr-auto">
                  <h2>Coming Soon...</h2>
                  <p>
                    Enter your email to know when this feature is available and
                    get some discounts and offers.
                  </p>
                  {this.state.subscriptionerror ? (
                    <Alert variant={"error"}>
                      {this.state.subscriptionerror}
                    </Alert>
                  ) : (
                    <></>
                  )}

                  {this.state.subscriptionsuccess ? (
                    <Alert variant={"success"}>
                      {this.state.subscriptionsuccess}
                    </Alert>
                  ) : (
                    <></>
                  )}
                  <Form onSubmit={this.handleSubmit} noValidate>
                    <FormField
                      placeholder={"Your Email"}
                      value={this.state.formData?.email}
                      error={this.state.errorData?.email}
                      name={"email"}
                      handle={this.handleChange}
                    />
                    <Button variant="primary" type="submit">
                      Subscribe Now
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <SideBarAdCard></SideBarAdCard>
            <RecentNews></RecentNews>
          </div>
        </div>
      </div>
    );
  }
}

export default ComingSoonController;
