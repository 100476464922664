import { StringsHi } from "./Strings.hi";
import { StringsEn } from "./Strings.en";
import  Card  from "./Card";
import Config from "./Config";
import { StringsAr } from "./Strings.ar";
 
class Book {     
   static _strings= {
      "default":StringsEn,
      "en":StringsEn,
      "hi":StringsHi,
      "ar":StringsAr,
   }; 
   static _lc="en"; 
   static _card=Card;
   static _config=Config;
   static get Strings() {
       return this._strings[this._lc||'en'];
   }
   static set Strings(strings) {
       this._strings[this._lc] = strings;
   }
   static get Card() {
      return this._card;
   }
   static set Card(card) {
      this._card=card;
   }
   static get Config() {
      return this._config;
   }
   static set Config(config) {
      this._config=config;
   }
   static get LC() {
      return this._lc;
   }
   static set LC(lc) {
      this._lc=lc;
   } 
};
export default Book; 