
import { request } from "./../config/request" 
export const ContactService = {
   
    async submitContact(formData,settings) {     
        const message = `
        <table>
            <tr>
                <td>Name</td>
                <td>${formData.name}</td>
            </tr>
            <tr>
                <td>Email</td>
                <td>${formData.email}</td>
            </tr>
            <tr>
                <td>Subject</td>
                <td>${formData.subject}</td>
            </tr>
            <tr>
                <td>Message</td>
                <td>${formData.message}</td>
            </tr>
        </table> 
        ` ;
        const messageText=`Name :${formData.name} ,Email :${formData.email} ,Subject :${formData.subject} ,Message :${formData.message} `;
        const senemail= settings.contact_email;
        const sensubject= settings.contact_subject??"Contact Us";

        const contact=await request.post('/api/email',{
            to: senemail,
            subject:sensubject,
            text: messageText,
            html: message
          }).then((response)=>{
            let proData={
                success:false,
            };
            if(response.status===200){
                proData={
                    success:true,
                }; 
            } 
            return proData;
        }).catch ((error) =>{ 
            return {
                success:false,
            };
        })        
        return contact;
    },
}