import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
// import Slider from "react-slick";
import Navbar from 'react-bootstrap/Navbar';

import "./../css/widget/NavTabBar.css";

class NavTabBar extends Component{
    constructor(props) {
        super(props);
        this.divRef = React.createRef();

        this.state = {
            categorys:[...props.categorys /*,{title:"Videos" ,slug:"videos",id:61}*/] ,
            active: props.active,
            navid: "category-navbar-nav",
            navmenu:"nav-menu",
            isMore:true,
            isLoaded:false,
            displaycount:0
        };        
        // this.handleResize = this.handleResize.bind(this);
    }       
    static getDerivedStateFromProps(props, state) {
        return {
            categorys: [...props.categorys /*,{title:"Videos" ,slug:"videos",id:61} */] ,
        };
    }   
    componentDidMount() {
        // Add the window resize event listener
        // window.addEventListener('resize', this.handleResize);
        // setTimeout(() => { 
        //     this.handleResize({});
        // }, 1000); 
    }

    // Lifecycle method called before the component unmounts
    componentWillUnmount() {
        // Remove the window resize event listener
        // window.removeEventListener('resize', this.handleResize);
    }
    // handleResize(e){ 
    //   if(!this.divRef){  
    //     return ;
    //   }
    //   const container = this.divRef.current;
    //   if (!container) { 
    //     return;
    //   } 
    //   const containerWidth = container.clientWidth;
    //   const spans = container.querySelectorAll('a');
    //   let totalSpanWidth = 0;
    //   let displaycount = 0;
    //   totalSpanWidth=(spans[0].clientWidth)+(spans[spans.length-1].clientWidth);//+5
    //   spans.forEach((span,k) => { 
    //     if(k!==0 && k!==(spans.length-1)){
    //         totalSpanWidth += span.clientWidth //+ (parseInt(window.getComputedStyle(span).marginRight)*2); 
    //         if (totalSpanWidth <= containerWidth) {
    //             displaycount += 1;
    //         }
    //     }
    //   }); 
    //   const isLoaded=totalSpanWidth>0?true:false;
    //   this.setState({displaycount,isLoaded})

    // }

    handleMoreClick = (e) =>  {
        e.preventDefault();
        let isMore=this.state.isMore?false:true;
        let navmenu=isMore?"nav-menu":"nav-menu showmore";
        this.setState({ isMore ,navmenu });
    } 
    render() {
        return (     
            <Navbar expand="lg" className="bg-body-tertiary">
                <Navbar.Toggle aria-controls={ this.state.navid } />
                <Navbar.Collapse className="basic-navbar-nav" id={ this.state.navid } >
                    <Nav className={ this.state.navmenu } >
                       
                        <div className="navbar-slider-outer"  ref={this.divRef}  > 
                          <Link className={ this.state.active==="all" ? 'active nav-link' : 'nav-link' } to="/">All</Link> 
                          {
                              this.state.categorys.map((cat)=>{
                                  return (
                                          <Link className={'nav-link morenav '+(this.state.active===cat.slug ?"active":"")} to={"/news/"+cat.slug}  key={cat.slug} >{cat.title}</Link>
                                  );
                                  
                              })
                              
                          } 
                          <Link  onClick={this.handleMoreClick} className='nav-link more-link' >{  this.state.isMore?('More'):("Less") }</Link>
                        </div> 
                    </Nav> 

                    <div className="navbar-dropdown-outer" hidden={this.state.isMore}  > 

                      {
                            this.state.categorys.map((cat,k)=>{
                                return (
                                  
                                        <Link className='nav-link drop-nav' attr-display={this.state.displaycount /* hidden={k<this.state.displaycount?true:false}*/ } to={"/news/"+cat.slug}  key={cat.slug} >{cat.title}</Link>
                                    
                                );
                                
                            })
                            
                        } 
                      </div>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
 

export default NavTabBar;