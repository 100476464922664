import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { dateFormat } from "./../config/common";

import noimage from "./../images/dailytime-noimg.png";

import "./../css/widget/SearchCard.css";
class SearchCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
      active: props.active,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news: props.news,
      active: props.active,
    };
  }
  render() {
    return (
       
        <div className=" col-md-6 block-col2" key={"latest-op-block" + this.state.news.id}>
          <div className="latest-wrap latest-wrap-row  ">
            {/* {this.state.news.categoryname.map((itm, ik) => {
              return (
                <span className="latest-txt" key={ik}>
                  {itm.category}
                </span>
              );
            })} */}
            <div className="news-slider2-wrap  ">
              <div className="news-slider2">
                <Card className="latest-block">
                  <Card.Body>
                    <div className="latest-block">
                      <div className="news-list-left">
                        
                          {this.state.news.subcategory?(<span className="latest-name">{this.state.news.subcategory} </span>):(<></>) }
                          <Link to={"/"+this.state.active +'/'+ this.state.news.slug}>
                        <div className="latest-img">
                          <img
                            src={
                              this.state.news.image
                                ? this.state.news.image
                                : noimage
                            }
                            alt=""
                          />
                        </div>
                        </Link>
                      </div>
                      <div className="news-list-right">
                        <div className="news-list-top">
                          {/* <span className="list-top-lft">
                  The Times of India
                </span> */}
                          <span className="list-top-rgt">
                            {dateFormat(this.state.news.publish_date, "d M Y")}
                          </span>
                        </div>
                        <Link to={"/" +this.state.active +'/'+ this.state.news.slug}>
                          <h2>{this.state.news.title}</h2>
                          <div
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html: this.state.news.content,
                            }}
                          ></div>
                        </Link>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="viewcount">
                              {this.state.news.views_count}
                            </span>
                            {/* <span className="like">55</span>
                  <span className="dislike"></span> */}
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div> 
      
    );
  }
}
export default SearchCard;
