import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";

import noimage from "./../images/dailytime-noimg.png";

import "./../css/widget/RecentNews.css";

import { NewsService } from "./../services/news";

class RecentNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
        news: [],
    }
  }
  componentDidMount() {
    this.fetchRecentPost();
  } 
  fetchRecentPost = async () => {
    const news = await NewsService.fetchRecentPost();
    this.setState({ news }); 
  };
  render() {
    return (
      <>
        
          <div className="recent-post">
            <span className="latest-txt">RECENT NEWS</span>
            {this.state.news.map((item, k) => {
              return (
                <Card className="recent-block" key={k}>
                  <Card.Body>
                    <Link to={"/" + item.slug}>
                      <span>{item.title}</span>
                      <div className="recent-img">
                        <img src={item.image ?? noimage} alt="" />
                      </div>
                    </Link>
                  </Card.Body>
                </Card>
              );
            })}
          </div> 
      </>
    );
  }
}
export default RecentNews;
