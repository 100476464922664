import React from "react";

export const dateFormat = (odate, format = "Y-m-d") => {
  format = format
    .replace("Y", "{Y}")
    .replace("m", "{m}")
    .replace("d", "{d}")
    .replace("M", "{M}")
    .replace("F", "{F}")
    .replace("D", "{D}")
    .replace("l", "{l}");
  const ndate = new Date(odate);
  return format
    .replace("{Y}", ndate.getFullYear())
    .replace("{m}", (ndate.getMonth() + 1).toString().padStart(2, "0"))
    .replace("{d}", ndate.getDate().toString().padStart(2, "0"))
    .replace("{M}", ndate.toLocaleString("en-IN", { month: "short" }))
    .replace("{F}", ndate.toLocaleString("en-IN", { month: "long" }))
    .replace("{D}", ndate.toLocaleString("en-IN", { weekday: "short" }))
    .replace("{l}", ndate.toLocaleString("en-IN", { weekday: "long" }));
};
export const titleSlug = (title) => {
  return title
    .toLowerCase()
    .trim()
    .replace(/[\s_]+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
};
export const AppContext = React.createContext(null);
