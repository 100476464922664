import React, { Component } from "react";
import FormCard from "../widgets/FormCard";
import FormField from "../widgets/FormField";
import FormTextArea from "../widgets/FormTextArea";
import RecentNews from "../widgets/RecentNews";
import SideBarAdCard from "../widgets/SideBarAdCard";
import {  Button } from "react-bootstrap";

import {  AppContext } from "./../config/common";


import { ContactService } from "./../services/contact";
import { Link } from "react-router-dom";
class ContactController extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
        ...props,
        formData:{
          name:null,
          email:null,
          subject:null,
          message:null,
        },
        errorData:{
          name:null,
          email:null,
          subject:null,
          message:null,
        },
        isSubmit:false,
        isSuccess:false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleSubmit=async (e)=>{
    e.preventDefault();
    e.stopPropagation();
    const { settings } = this.context;
    let formData = this.state.formData;
    let errorData = {}
    let isInValid =false;
    for (const name in formData) {
      errorData[name]="The "+name+" field is required"
      if (Object.hasOwnProperty.call(formData, name)) {
          if(formData[name]&&formData[name]!==""){
            errorData[name]=null;
          }else{
            isInValid =true;
          }
      } else{
        isInValid =true;
      }
    }
    let isSuccess=false;
    let isSubmit=isInValid?false:true;
    this.setState({ errorData ,isSubmit,isSuccess}); 
    if(!isInValid){
      const contact=await ContactService.submitContact(formData,settings);
      if(contact.success){
        formData={
          name:null,
          email:null,
          subject:null,
          message:null,
        };  
        isSuccess=true;
      } 
      isSubmit=false;
      this.setState({ formData,isSubmit ,isSuccess});
    }
    return !isInValid;
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value
    } 
    this.setState({ formData }); 
    if(!value||value===""){
      const errorData = {
        ...this.state.errorData,
        [name]: "The "+name+" field is required"
      }  
      this.setState({ errorData }); 
    }else{
      const errorData = {
        ...this.state.errorData,
        [name]:null
      }  
      this.setState({ errorData });       
    }
  };
  render() {
    
    return (
      <div className="home-page-outer">
        <div className="row row-two">
          <div className="col-md-9">
            <div className="contact-wrap">
              <div className="contact-row row">
              <div className="contact-col col-md-7 mr-auto">
                  {
                    this.state.formData&&!this.state.isSuccess?(
                      <FormCard handle={this.handleSubmit}>
                        <h2>Contact Us</h2>
                        <FormField placeholder={"Name"} value={ this.state.formData?.name } error={ this.state.errorData?.name }  name={"name"} handle={this.handleChange} />
                        <FormField placeholder={"Email"} value={ this.state.formData?.email } error={ this.state.errorData?.email }  name={"email"} handle={this.handleChange}/>
                        <FormField placeholder={"Subject"} value={ this.state.formData?.subject } error={ this.state.errorData?.subject }  name={"subject"}   handle={this.handleChange}/>
                        <FormTextArea placeholder={"Message"} value={ this.state.formData?.message } error={ this.state.errorData?.message }  name={"message"}   handle={this.handleChange}/> 
                        <Button variant="primary" type="submit" disabled={this.state.isSubmit} >Submit</Button>
                      </FormCard>
                    ):(
                      <></>
                    )
                  }
                  {
                    this.state.isSuccess?(<>
                    
                    <div className="thank-you">
                        <h1>Thank You!</h1>
                        <p>Your message has been successfully submitted.</p>
                        <Link to={"/"} className="home-link">Return to Home</Link> 
                    </div>
                    </>):(<></>)
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <SideBarAdCard></SideBarAdCard>
            <RecentNews></RecentNews>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactController;
