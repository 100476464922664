import { SiteService } from "../services/site";

export const StringsAr = {
  profile: {
    first_name: {
      title: "الاسم الأول",
      placeholder: "الاسم الأول",
      name: "الاسم الأول",
      validate: async (value) => {
        
        const fieldName = StringsAr.profile.first_name.name;
        if (!value) {
          return {
            valid: false,
            
            message: `الحقل ${fieldName} مطلوب.`,
          };
        }
        if (value.length < 3) {
          return {
            valid: false,
            
            message: `يجب أن يتكون ${fieldName} من 3 أحرف على الأقل.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    last_name: {
      title: "اسم العائلة",
      placeholder: "اسم العائلة",
      name: "اسم العائلة",
      validate: async (value) => {
        
        const fieldName = StringsAr.profile.last_name.name;
        if (!value) {
          return {
            valid: false,
            
            message: `الحقل ${fieldName} مطلوب.`,
          };
        }
        if (value.length < 3) {
          return {
            valid: false,
            
            message: `يجب أن يتكون ${fieldName} من 3 أحرف على الأقل.`,
          };
        }
        return { valid: true,  message: "" };
      },
    }, 
    email: {
      title: "معرف البريد الإلكتروني",
      placeholder: "معرف البريد الإلكتروني",
      name: "بريد إلكتروني",
      validate: async (value) => {
        
        const fieldName = StringsAr.profile.email.name;
        if (!value) {
          return {
            valid: false,
            
            message: `الحقل ${fieldName} مطلوب.`,
          };
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
          return {
            valid: false,
            
            message: `يجب أن يكون ${fieldName} عنوان بريد إلكتروني صالحًا.`,
          };
        }
        if(await SiteService.checkEmailExist(value)){
          return {
            valid: false,            
            message: `لقد تم أخذ ${fieldName} بالفعل.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    mobile: {
      title: "رقم الجوال",
      placeholder: "رقم الجوال",
      name: "متحرك",
      validate: async (value) => {
        
        const fieldName = StringsAr.profile.mobile.name;
        if (!value) {
          return {
            valid: false,
            
            message: `الحقل ${fieldName} مطلوب.`,
          };
        }
        const mobilePattern = /^\d{10}$/;
        if (!mobilePattern.test(value)) {
          return {
            valid: false,
            message: `يجب أن يكون ${fieldName} رقمًا صالحًا مكونًا من 10 أرقام.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    currentPassword:{
      title: "كلمة المرور الحالية",
      placeholder: "كلمة المرور الحالية",
      name: "كلمة المرور الحالية",
      validate: async (value) => {
        
        const fieldName = StringsAr.profile.currentPassword.name;
        if (!value) {
          return {
            valid: false,
            
            message: `الحقل ${fieldName} مطلوب.`,
          };
        }
        if (value.length < 6) {
          return {
            valid: false,
            
            message: `يجب أن يتكون ${fieldName} من 6 أحرف على الأقل.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    password: {
      title: "كلمة المرور",
      placeholder: "كلمة المرور",
      name: "كلمة المرور",
      validate: async (value) => {
        
        const fieldName = StringsAr.profile.password.name;
        if (!value) {
          return {
            valid: false,
            
            message: `الحقل ${fieldName} مطلوب.`,
          };
        }
        if (value.length < 6) {
          return {
            valid: false,
            
            message: `يجب أن يتكون ${fieldName} من 6 أحرف على الأقل.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    cpassword: {
      title: "أعد إدخال كلمة المرور",
      placeholder: "أعد إدخال كلمة المرور",
      name: "أعد إدخال كلمة المرور",
      confirm:(v1,v2)=>{
        const field1 = StringsAr.profile.password.name;
        const field2 = StringsAr.profile.cpassword.name;
        if(v1!==v2){
          return { confirm: false,  message: `The ${field2} must be same as ${field1}.` };
        }
        return { confirm: true,  message: "" };
      },
      validate: async (value) => {
        
        const fieldName = StringsAr.profile.cpassword.name;
        if (!value) {
          return {
            valid: false,
            
            message: `الحقل ${fieldName} مطلوب.`,
          };
        }
        if (value.length < 6) {
          return {
            valid: false,
            
            message: `يجب أن يتكون ${fieldName} من 6 أحرف على الأقل.`,
          };
        }
        return { valid: true,  message: "" };
      },
    },
    accept_legal_agreement:{
      title: "Legal Agreement",
      placeholder: "Legal Agreement",
      name: "legal agreement", 
      validate: async (value) => {        
        const fieldName = StringsAr.profile.cpassword.name;
        if (!value) {
          return {
            valid: false, 
            message: `Accept ${fieldName}  is required.`,
          };
        } 
        return { valid: true,  message: "" };
      },

    }
  },
  menu: {
    home: "بيت",
    about: "عن",
    products: "منتجات",
    contact: "اتصل بنا",
    account: "حساب",
    login: "تسجيل الدخول",
    logout: "تسجيل الخروج",
    signup: "اشتراك",
  },
  subscription: {
    title: "النشرة الإخبارية",
    description: "اشترك للحصول على آخر التحديثات والأخبار",
    button: "إشترك الآن",
    success: "شكرا لك على الاشتراك. نحن نتطلع إلى البقاء على اتصال وتقديم محتوى رائع لك!"
  },
  news: {
    popular_category: "فئة شعبية",
    recent_more: "المزيد من الأخبار الأخيرة",
  },
};
