import Firm from "./Firm";
import Lawyer from "./Lawyer";
import Book from "./Book";
import { StringsAr } from "./Strings.ar";
import { StringsEn } from "./Strings.en";
import { StringsHi } from "./Strings.hi";

class Catalog  {
    static _strings= {
       "default":StringsEn,
       "en":StringsEn,
       "hi":StringsHi,
       "ar":StringsAr,
    }; 
    static _lawyer=Lawyer;
    static _firm=Firm;
    static _book=Book;
    static _lc="en";
    static _token=null; 
    static _is_load=false; 
    static _rediarectLogin="/account"; 
    static _message=null;
    static get Strings() {
        return this._strings[this._lc]||this._strings['default'];
    }
    static set Strings(strings) {
        this._strings[this._lc] = strings;
    }
    static get isLoad() {
        return this._is_load;
    }
    static set isLoad(isload) {
        this._is_load = isload;
    }
    static get RediarectLogin() {
        const redirect=this._rediarectLogin;
        this._rediarectLogin="/account";
        return redirect;
    }
    static set RediarectLogin(rediarectLogin) {
        this._rediarectLogin = rediarectLogin;
    }

    static get Message() {
        const message=this._message;
        this._message=null;
        return message;
    }
    static get isMessage() { 
        return this._message?true:false;
    }
    static set isMessage(ismessage) { 
        
    }
    static set Message(message) {
        this._message = message;
    }

    static get Session() {  
        return JSON.parse(localStorage.getItem("_session"))||{};
    }
    static set Session(session) {
        localStorage.setItem("_session",JSON.stringify(session||{}))
    }
    static get Lawyer() {
        return this._lawyer;
    }
    static set Lawyer(lawyer) {
        this._lawyer = lawyer;
    }
    static get Firm() {
        return this._firm;
    }
    static set Firm(firm) {
        this._firm = firm;
    }
    static get Book() {
        return this._book;
    }
    static set Book(books) {
        this._book = books;
    }
    static get LC() {
       return this._lc;
    }
    static set LC(lc) {
       this._lc=lc;
       this.Lawyer.LC=lc;
       this.Firm.LC=lc;
       this.Book.LC=lc;
    }
    static get Token() {
       return this._token;
    }
    static set Token(token) {
       this._token=token;
    }
};
export default Catalog 