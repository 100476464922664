
import { request } from "./../config/request"
import { API_ENDPOINT } from "./../config/constant"


export const HomeService = {
    async fetchProducts() {        
        const products=await request.get('/api/products?populate=*').then((response)=>{
            let proData=[];
            let pagination=null;
            if(response.status===200){

                for (let pro in response.data.data) {
                    proData.push({
                        ...response.data.data[pro].attributes,
                        id: response.data.data[pro].id,
                        image:API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url,
                    })
                }
                pagination=response.data.meta.pagination;
            }

            return {
                data:proData,
                pagination:pagination
            };
        }).catch ((error) =>{
            return { data:[], error:error };
        })        
        return products;
    },
    async fetchCategory() {
        const products=await request.get('/api/categories?filters[show_nav][$eq]=true&filters[posts][id][$notNull]=true&sort[0]=show_order:asc').then((response)=>{
            let proData=[];
            if(response.status===200){
                for (let pro in response.data.data) {
                    proData.push({
                        id:response.data.data[pro].id,
                        title:response.data.data[pro].attributes.name,
                        slug:response.data.data[pro].attributes.slug,
                    })
                }
            }
            return proData;
        }).catch ((error) =>{
            return [];
        })        
        return products;
    },    
    async fetchAds() {        
        const ads=await request.get('/api/site-ads?populate=*').then((response)=>{
            let proData={
                sidebar:[],
                onecol:[],
                twocol:[]
            };
            if(response.status===200){
                for (let pro in response.data.data) {
                    switch (response.data.data[pro].attributes.type) {
                        case "Side Bar":                            
                                proData.sidebar.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;                        
                        case "One Column into List":                            
                                proData.onecol.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                                                      
                        case "Two Column into List":                            
                                proData.twocol.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                    
                        default:
                                                        
                                proData.sidebar.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                    }
                }
            }
            return proData;
        }).catch ((error) =>{
            return {
                sidebar:[],
                onecol:[],
                twocol:[]
            };
        })        
        return ads;
    },
    async fetchPost() {         
        const posts=await request.get(`/api/posts?pagination[pageSize]=150&populate[image]=*&populate[categories][populate][0]=parent_category&sort[0]=publish_date:desc`).then((response)=>{
            let proData={
                'trending':[],
                'latest':[],
                'list':{},
                'category':{},
            };
            if(response.status===200){
                for (let pro in response.data.data) {
                    const news=response.data.data[pro].attributes;
                    news.image=news.image.data?API_ENDPOINT+news.image.data.attributes.url:null
                    news.id=response.data.data[pro].id;

                    if(news.trending){
                        news.category="Trending News";
                        proData.trending.push(news)
                    }
                    if(pro<10){
                        news.category="Latest News";
                        proData.latest.push(news)
                    }

                    if(response.data.data[pro].attributes.categories&&response.data.data[pro].attributes.categories.data){
                        for (const key in response.data.data[pro].attributes.categories.data) {
                            const cat = response.data.data[pro].attributes.categories.data[key];
                            
                            news.category="";
                            news.subcategory="";
                            if(!proData.list[`${cat.attributes.slug}`]){
                                proData.list[`${cat.attributes.slug}`]=[];
                            }
                            if(cat.attributes.parent_category&&cat.attributes.parent_category.data){ 
                                news.category=cat.attributes.parent_category.data.attributes.name
                                news.subcategory=cat.attributes.name 
                                if(!proData.category[`${cat.attributes.parent_category.data.attributes.slug}`]){
                                    proData.category[`${cat.attributes.parent_category.data.attributes.slug}`]={};
                                }
                                if(!proData.category[`${cat.attributes.slug}`]){
                                    proData.category[`${cat.attributes.parent_category.data.attributes.slug}`][`${cat.attributes.slug}`]=[];
                                }
                                proData.category[`${cat.attributes.parent_category.data.attributes.slug}`][`${cat.attributes.slug}`].push(news);
                            }else{
                                news.category=cat.attributes.name 
                                if(!proData.category[`${cat.attributes.slug}`]){
                                    proData.category[`${cat.attributes.slug}`]={};
                                }
                                if(!proData.category[`${cat.attributes.slug}`][`${cat.attributes.slug}`]){
                                    proData.category[`${cat.attributes.slug}`][`${cat.attributes.slug}`]=[];
                                }
                                proData.category[`${cat.attributes.slug}`][`${cat.attributes.slug}`].push(news);
                            }
                            proData.list[`${cat.attributes.slug}`].push(news);
                        } 
                    } 
                }
            } 
            return proData;
        }).catch ((error) =>{ 
            return {
                'trending':[],
                'latest':[],
                'list':{},
                'category':{},
            };
        })  
        return posts;
    },    
};