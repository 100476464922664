import { request } from "./../config/request";
import { API_ENDPOINT } from "./../config/constant";

export const NewsService = {
  async fetchProducts() {
    const products = await request
      .get("/api/products?populate=*")
      .then((response) => {
        let proData = [];
        let pagination = null;
        if (response.status === 200) {
          for (let pro in response.data.data) {
            proData.push({
              ...response.data.data[pro].attributes,
              id: response.data.data[pro].id, 
              image:
                API_ENDPOINT +
                response.data.data[pro].attributes.image.data.attributes.url,
            });
          }
          pagination = response.data.meta.pagination;
        }

        return {
          data: proData,
          pagination: pagination,
        };
      })
      .catch((error) => {
        return { data: [], error: error };
      });
    return products;
  },
  async fetchCategory() {
    const products = await request
      .get(
        "/api/categories?filters[show_nav][$eq]=true&filters[posts][id][$notNull]=true&sort[0]=show_order:asc"
      )
      .then((response) => {
        let proData = [];
        if (response.status === 200) {
          for (let pro in response.data.data) {
            proData.push({
              id: response.data.data[pro].id,
              title: response.data.data[pro].attributes.name,
              slug: response.data.data[pro].attributes.slug,
            });
          }
        }
        return proData;
      })
      .catch((error) => {
        return [];
      });
    return products;
  },
  async fetchAds() {
    const ads = await request
      .get("/api/site-ads?populate=*")
      .then((response) => {
        let proData = {
          sidebar: [],
          onecol: [],
          twocol: [],
        };
        if (response.status === 200) {
          for (let pro in response.data.data) {
            switch (response.data.data[pro].attributes.type) {
              case "Side Bar":
                proData.sidebar.push({
                  id: response.data.data[pro].id,
                  title: response.data.data[pro].attributes.title,
                  youtube: response.data.data[pro].attributes.youtube,
                  link: response.data.data[pro].attributes.link,
                  image: response.data.data[pro].attributes.image.data
                    ? API_ENDPOINT +
                      response.data.data[pro].attributes.image.data.attributes
                        .url
                    : null,
                });
                break;
              case "One Column into List":
                proData.onecol.push({
                  id: response.data.data[pro].id,
                  title: response.data.data[pro].attributes.title,
                  youtube: response.data.data[pro].attributes.youtube,
                  link: response.data.data[pro].attributes.link,
                  image: response.data.data[pro].attributes.image.data
                    ? API_ENDPOINT +
                      response.data.data[pro].attributes.image.data.attributes
                        .url
                    : null,
                });
                break;

              case "Two Column into List":
                proData.twocol.push({
                  id: response.data.data[pro].id,
                  title: response.data.data[pro].attributes.title,
                  youtube: response.data.data[pro].attributes.youtube,
                  link: response.data.data[pro].attributes.link,
                  image: response.data.data[pro].attributes.image.data
                    ? API_ENDPOINT +
                      response.data.data[pro].attributes.image.data.attributes
                        .url
                    : null,
                });
                break;

              default:
                proData.sidebar.push({
                  id: response.data.data[pro].id,
                  title: response.data.data[pro].attributes.title,
                  youtube: response.data.data[pro].attributes.youtube,
                  link: response.data.data[pro].attributes.link,
                  image: response.data.data[pro].attributes.image.data
                    ? API_ENDPOINT +
                      response.data.data[pro].attributes.image.data.attributes
                        .url
                    : null,
                });
                break;
            }
          }
        }
        return proData;
      })
      .catch((error) => {
        return {
          sidebar: [],
          onecol: [],
          twocol: [],
        };
      });
    return ads;
  },
  async updatePostView(news) {
    const ismatched=(news.content??"").match(/https:\/\/dailylawtimes\.in\/wp-content\/\uploads\/\d{4}\/\d{2}/gi);
    if(ismatched&&ismatched.length>0){
      console.log(ismatched)
      const post = await request
      .put(`/api/posts/${news.id}`, {
        data: {
          views_count: parseInt(news.views_count ?? 0) + 1, 
          // content:news.content.replace(/https\:\/\/dailylawtimes\.in\/wp\-content\/\uploads\/\d{4}\/\d{2}/gi,"/uploads")
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          news.views_count = response.data.data.attributes.views_count;
        }
        return news;
      })
      .catch((error) => {
        return news;
      });
    return post;
    }else{
      const post = await request
      .put(`/api/posts/${news.id}`, {
        data: {
          views_count: parseInt(news.views_count ?? 0) + 1, 
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          news.views_count = response.data.data.attributes.views_count;
        }
        return news;
      })
      .catch((error) => {
        return news;
      });
    return post;
    } 
    
  },
  async fetchPostBySlug(slug) {
    const posts = await request
      .get(
        `/api/posts?populate[image]=true&populate[users_permissions_user]=true&populate[tags]=*&populate[categories][populate][0]=parent_category&filters[slug][$eq]=${slug}&sort[0]=publish_date:desc`
      )
      .then((response) => {
        let proData = {};
        if (
          response.status === 200 &&
          response.data.data &&
          response.data.data[0]
        ) {
          proData = response.data.data[0].attributes;
          proData.image = proData.image.data
            ? API_ENDPOINT + proData.image.data.attributes.url
            : null;

          if(proData.image===null&&proData.youtube){
            proData.image="https://img.youtube.com/vi/"+proData.youtube+"/default.jpg"
          }
          proData.id = response.data.data[0].id;
          if (
            proData.users_permissions_user &&
            proData.users_permissions_user.data
          ) {
            proData.user = proData.users_permissions_user.data.attributes;
            proData.user.id = proData.users_permissions_user.data.id;
          } else {
            proData.user = {};
          }
          proData.newstags=[];
          if(proData.tags&&proData.tags.data){
            for (const key in proData.tags.data) {
              if (Object.hasOwnProperty.call(proData.tags.data, key)&&proData.tags.data[key].attributes.name!=="&#038;"&&proData.tags.data[key].attributes.name!=="&amp;") { 
                proData.newstags.push(proData.tags.data[key].attributes.name)  
              }
            }
          }
        }
        return proData;
      })
      .catch((error) => {
        return {};
      });
    return posts;
  },
  async fetchPostByCategory(slug,page=1) {
    let apiLink = `/api/posts?pagination[page]=${page}&pagination[pageSize]=24&populate[image]=*&populate[categories][populate][0]=parent_category&sort[0]=publish_date:desc`;
    if (slug !== "latest" && slug !== "all") {
      if (slug === "trending") {
        apiLink = `/api/posts?pagination[page]=${page}&pagination[pageSize]=24&populate[image]=*&populate[categories][populate][0]=parent_category&filters[trending][$eq]=true&sort[0]=publish_date:desc`;
      } else {
        apiLink = `/api/posts?pagination[page]=${page}&pagination[pageSize]=24&populate[image]=*&populate[categories][populate][0]=parent_category&filters[$or][0][categories][slug][$contains]=${slug}&filters[$or][1][categories][parent_category][slug][$contains]=${slug}&sort[0]=publish_date:desc`;
      }
    }
    const posts = await request
      .get(apiLink)
      .then((response) => {
        let proData = {
          news: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        if (response.status === 200) {
          for (let pro in response.data.data) {
            const news = response.data.data[pro].attributes;
            news.image = news.image.data
              ? API_ENDPOINT + news.image.data.attributes.url
              : null;
            news.id = response.data.data[pro].id;
            news.categoryname = [];

            if(news.image===null&&news.youtube){
                news.image="https://img.youtube.com/vi/"+news.youtube+"/default.jpg"
            }
            if (
              response.data.data[pro].attributes.categories &&
              response.data.data[pro].attributes.categories.data
            ) {
              for (const key in response.data.data[pro].attributes.categories
                .data) {
                const cat =
                  response.data.data[pro].attributes.categories.data[key];
                let category = "";
                let subcategory = "";
                if (
                  cat.attributes.parent_category &&
                  cat.attributes.parent_category.data
                ) {
                  category =
                    cat.attributes.parent_category.data.attributes.name;
                  subcategory = cat.attributes.name;
                } else {
                  category = cat.attributes.name;
                }
                news.categoryname.push({
                  category: category,
                  subcategory: subcategory,
                });
              }
            }

            proData.news.push(news);
          }
          proData.pagination = response.data.meta.pagination;
        }
        return proData;
      })
      .catch((error) => {
        return {};
      });
    return posts;
  },
  async fetchPostCountByCategory(slug) {
    let apiLink = `/api/posts?pagination[pageSize]=1&populate[image]=*&populate[categories][populate][0]=parent_category&sort[0]=publish_date:desc`;
    if (slug !== "latest" && slug !== "all") {
      if (slug === "trending") {
        apiLink = `/api/posts?pagination[pageSize]=1&populate[image]=*&populate[categories][populate][0]=parent_category&filters[trending][$eq]=true&sort[0]=publish_date:desc`;
      } else {
        apiLink = `/api/posts?pagination[pageSize]=1&populate[image]=*&populate[categories][populate][0]=parent_category&filters[$or][0][categories][slug][$contains]=${slug}&filters[$or][1][categories][parent_category][slug][$contains]=${slug}&sort[0]=publish_date:desc`;
      }
    }
    const posts = await request
      .get(apiLink)
      .then((response) => {
        if (response.status === 200) {
          return response.data.meta.pagination.total ?? 0;
        } else {
          return 0;
        }
      })
      .catch((error) => {
        return 0;
      });
    return posts;
  },

  async fetchPostByTag(slug,page=1) {
    let apiLink = `/api/posts?pagination[page]=${page}&pagination[pageSize]=24&populate[image]=*&populate[categories][populate][0]=parent_category&filters[tags][slug][$contains]=${slug}&sort[0]=publish_date:desc`;
     
    const posts = await request
      .get(apiLink)
      .then((response) => {
        let proData = {
          news: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        if (response.status === 200) {
          for (let pro in response.data.data) {
            const news = response.data.data[pro].attributes;
            news.image = news.image.data
              ? API_ENDPOINT + news.image.data.attributes.url
              : null;
            news.id = response.data.data[pro].id;
            news.categoryname = [];

            if(news.image===null&&news.youtube){
              news.image="https://img.youtube.com/vi/"+news.youtube+"/default.jpg"
          }
            if (
              response.data.data[pro].attributes.categories &&
              response.data.data[pro].attributes.categories.data
            ) {
              for (const key in response.data.data[pro].attributes.categories
                .data) {
                const cat =
                  response.data.data[pro].attributes.categories.data[key];
                let category = "";
                let subcategory = "";
                if (
                  cat.attributes.parent_category &&
                  cat.attributes.parent_category.data
                ) {
                  category =
                    cat.attributes.parent_category.data.attributes.name;
                  subcategory = cat.attributes.name;
                } else {
                  category = cat.attributes.name;
                }
                news.categoryname.push({
                  category: category,
                  subcategory: subcategory,
                });
              }
            }

            proData.news.push(news);
          }
          proData.pagination = response.data.meta.pagination;
        }
        return proData;
      })
      .catch((error) => {
        return {};
      });
    return posts;
  },
  async fetchTrendingPost() {
    let apiLink = `/api/posts?filters[trending][$eq]=true&populate[image]=*&populate[categories][populate][0]=parent_category&sort[0]=publish_date:desc`;

    const posts = await request
      .get(apiLink)
      .then((response) => {
        let proData = [];

        for (let pro in response.data.data) {
          const news = response.data.data[pro].attributes;
          news.image = news.image.data
            ? API_ENDPOINT + news.image.data.attributes.url
            : null;
          news.id = response.data.data[pro].id;
          news.categoryname = [];

          if(news.image===null&&news.youtube){
            news.image="https://img.youtube.com/vi/"+news.youtube+"/default.jpg"
        }
          if (
            response.data.data[pro].attributes.categories &&
            response.data.data[pro].attributes.categories.data
          ) {
            for (const key in response.data.data[pro].attributes.categories
              .data) {
              const cat =
                response.data.data[pro].attributes.categories.data[key];
              let category = "";
              let subcategory = "";
              if (
                cat.attributes.parent_category &&
                cat.attributes.parent_category.data
              ) {
                category = cat.attributes.parent_category.data.attributes.name;
                subcategory = cat.attributes.name;
              } else {
                category = cat.attributes.name;
              }
              news.categoryname.push({
                category: category,
                subcategory: subcategory,
              });
            }
          }

          proData.push(news);
        }
        return proData;
      })
      .catch((error) => {
        return [];
      });
    return posts;
  },

  async fetchLatestPost() {
    let apiLink = `/api/posts?populate[image]=*&populate[categories][populate][0]=parent_category&sort[0]=publish_date:desc`;

    const posts = await request
      .get(apiLink)
      .then((response) => {
        let proData = [];

        for (let pro in response.data.data) {
          const news = response.data.data[pro].attributes;
          news.image = news.image.data
            ? API_ENDPOINT + news.image.data.attributes.url
            : null;
          news.id = response.data.data[pro].id;
          news.categoryname = [];

          if(news.image===null&&news.youtube){
            news.image="https://img.youtube.com/vi/"+news.youtube+"/default.jpg"
        }
          if (
            response.data.data[pro].attributes.categories &&
            response.data.data[pro].attributes.categories.data
          ) {
            for (const key in response.data.data[pro].attributes.categories
              .data) {
              const cat =
                response.data.data[pro].attributes.categories.data[key];
              let category = "";
              let subcategory = "";
              if (
                cat.attributes.parent_category &&
                cat.attributes.parent_category.data
              ) {
                category = cat.attributes.parent_category.data.attributes.name;
                subcategory = cat.attributes.name;
              } else {
                category = cat.attributes.name;
              }
              news.categoryname.push({
                category: category,
                subcategory: subcategory,
              });
            }
          }

          proData.push(news);
        }
        return proData;
      })
      .catch((error) => { 
        return [];
      });
    return posts;
  },
  async fetchRecentPost() {
    let apiLink = `/api/posts?pagination[pageSize]=6&populate[image]=*&populate[categories][populate][0]=parent_category&sort[0]=publish_date:desc`;

    const posts = await request
      .get(apiLink)
      .then((response) => {
        let proData = [];

        for (let pro in response.data.data) {
          const news = response.data.data[pro].attributes;
          news.image = news.image.data
            ? API_ENDPOINT + news.image.data.attributes.url
            : null;
          news.id = response.data.data[pro].id;
          news.categoryname = [];

          if(news.image===null&&news.youtube){
              news.image="https://img.youtube.com/vi/"+news.youtube+"/default.jpg"
          }
          if (
            response.data.data[pro].attributes.categories &&
            response.data.data[pro].attributes.categories.data
          ) {
            for (const key in response.data.data[pro].attributes.categories
              .data) {
              const cat =
                response.data.data[pro].attributes.categories.data[key];
              let category = "";
              let subcategory = "";
              if (
                cat.attributes.parent_category &&
                cat.attributes.parent_category.data
              ) {
                category = cat.attributes.parent_category.data.attributes.name;
                subcategory = cat.attributes.name;
              } else {
                category = cat.attributes.name;
              }
              news.categoryname.push({
                category: category,
                subcategory: subcategory,
              });
            }
          }

          proData.push(news);
        }
        return proData;
      })
      .catch((error) => {
        return [];
      });
    return posts;
  },
  // fetchRelatedNews: async (newsdata) => {
  //   try {
  //     // Assuming your backend API has an endpoint to fetch related news
  //     const response = await fetch(`${API_ENDPOINT}/related-news/${newsdata.id}`); // Adjust the endpoint according to your API
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch related news");
  //     }
  //     const data = await response.json();
  //     return data; // Assuming data is an array of related news items
  //   } catch (error) {
  //     console.error("Error fetching related news:", error);
  //     return []; // Return an empty array or handle the error accordingly
  //   }
  // },
  
};
