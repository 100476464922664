
import Catalog from "../Catalog";
import { authrequest, gustrequest, multipart, request } from "./../config/request" 
export const SiteService = {
    
    async fetchSiteSettings() {        
        const setting=await request.get('/api/site-setting').then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data.data.attributes; 
            }
            return proData;
        }).catch ((error) =>{ 
            return {};
        })        
        return setting;
    },
    async fetchSocialIcons() {        
        const socialicon=await request.get('/api/social-link').then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data.data.attributes; 
            }
            return proData;
        }).catch ((error) =>{ 
            return {}; 
        })        
        return socialicon;
    },
    async subscribeNewsletter(user) {        
        const subscription=await request.post('/api/auth/local/register',user).then((response)=>{
            let proData={};
            if(response.data){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return subscription;
    }, 
    async uploadMedia(formMedia) {        
        const files=await multipart.post('/api/upload/',formMedia).then((response)=>{
            let uploads=[];
            if(response.data){
                uploads=response.data; 
            } 
            return uploads;
        }).catch ((error) =>{  
            return [];
        })        
        return files;
    }, 
    async changePassword(formdata) {      
        const sended=await authrequest(Catalog.Session.token).post('/api/auth/change-password',formdata).then((response)=>{
            const sendmail=response.data; 
            return sendmail;
        }).catch ((error) =>{    
            return error.response.data;
        })        
        return sended;     

    },

    async updateUser(user,id) {        
        const userdata=await request.put(`/api/users/${id}`,user).then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return userdata;
    }, 
    async resetPassword(formdata) {      
        const sended=await gustrequest.post('/api/auth/reset-password',formdata).then((response)=>{
            let sendmail=false;
            if(response.status===200){
                sendmail=true;
            } 
            return sendmail;
        }).catch ((error) =>{   
            return false;
        })        
        return sended;     

    },
    async forgetPassword(email) {        
        const sended=await gustrequest.post('/api/auth/forgot-password',email).then((response)=>{
            let sendmail=false;
            if(response.status===200){
                sendmail=true;
            } 
            return sendmail;
        }).catch ((error) =>{   
            return false;
        })        
        return sended;
    }, 
    async loginUser(auth) {        
        const userdata=await gustrequest.post('/api/auth/local',auth).then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{   
            return error.response.data;
        })        
        return userdata;
    }, 
    async registerUser(user) {        
        const userdata=await request.post('/api/users',user).then((response)=>{
            let proData={};
            if(response.data){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return userdata;
    }, 
    async registerLawyer(profile) {        
        const userdata=await request.post('/api/lawyers',{data:profile}).then((response)=>{
            let proData={};
            if(response.data){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return userdata;
    }, 
    async registerFirm(profile) {        
        const userdata=await request.post('/api/firms',{data:profile}).then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return userdata;
    }, 
    async updateRole(role,id) {        
        const userdata=await request.put(`/api/users/${id}`,{data:{role:role}}).then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return userdata;
    }, 
    async checkEmailExist(email) {        
        const userdata=await request.get(`/api/users?filters[email][$eq]=${email}&fields[0]=email`).then((response)=>{
            if(response.status===200&&response.data.length>0){ 
                return true
            } else{
                return false
            }
        }).catch ((error) =>{  
            return false;
        })        
        return userdata;
    },
    async subscribeComingSoon(user) {        
        const subscription=await request.post('/api/coming-soon-users',{
            data:user
        }).then((response)=>{
            let proData={};
            if(response.data){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return null;
        })        
        return subscription;
    }, 
    async fetchPagebySlug(slug) {        
        const page=await request.get(`/api/hosted-pages?filters[name][$eq]=${slug}&sort[0]=id:desc`).then((response)=>{
            let proData={};
            if (
                response.status === 200 &&
                response.data.data &&
                response.data.data[0]
              ) {
                proData=response.data.data[0].attributes;
                proData.id=response.data.data[0].id 
            } 
            return proData;
        }).catch ((error) =>{  
            return null;
        })        
        return page;
    }, 
}