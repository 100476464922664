import React, { Component } from "react";
import { AppContext, titleSlug } from "../config/common";
import Catalog from "../Catalog";
import { Link, Navigate } from "react-router-dom";
import { Form, Col, Nav, Row, Tab, Card, Button, Alert, Badge } from "react-bootstrap";
import FormField from "../widgets/FormField";

import { StoreService } from "../services/store";
import { SiteService } from "../services/site";
import FormFile from "../widgets/FormFile";
import FormDatePicker from "../widgets/FormDatePicker";
import FormDropdown from "../widgets/FormDropdown";
import FormTextArea from "../widgets/FormTextArea";

class AccountController extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props); 
    const timeied = new Date().getTime();
    this.state = {
      ...props,
      formLoginId: "frmAuth" + timeied,
      formProfileId: "frmPro" + timeied,
      account: {},
      accountFormData: {},
      resetFormData: {
        currentPassword:null,
        password:null,
        cpassword:null,
      },
      orders:{
        data: [],
        pagination: {
          page: 1,
          pageSize: 25,
          pageCount: 1,
          total: 0,
        },
      },
      profile: {},
      profileFormData: {},
      errorData: {},
      readprofile: true,
      profileForm: true,
      error:null,
      success:null
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (Catalog.Session.isLogined) {
      this.fetchAccount();
    }
  }

  componentDidUpdate() {
    if (
      Catalog.Session.isLogined &&
      Catalog.Session.user.id !== this.state.account.id
    ) {
      this.fetchAccount();
    }
  }
  fetchAccount = async () => {
    const id = Catalog.Session.user.id;
    const account = await StoreService.fetchAccount(id);
    const accountFormData = {first_name:account.first_name,last_name:account.last_name};
    this.setState({ account, accountFormData });
    if (account.role.id === 5 || account.role.id===6) {
      this.fetchLawyer();
    } 
    if (account.role.id === 4) {
      this.fetchOrder();
    }
  };
  fetchOrder = async () => {
    const id = Catalog.Session.user.id;
    const orders = await StoreService.fetchOrder(id);
    this.setState({ orders });
  };

  fetchLawyer = async () => {
    const id = Catalog.Session.user.id;
    const profile = await StoreService.fetchLawyer(id);
    const {PrimaryImageId,image,Category,...FormData}=profile;
    const profileFormData ={
      ...FormData,
      PrimaryImageId:PrimaryImageId?.data?.id??null
    }
    this.setState({ profile ,profileFormData}); 
  }; 
  handlePasswordChange = async (e) => {
    let catelog = Catalog;
    const { name, value } = e.target;
    const resetFormData = {
      ...this.state.resetFormData,
      [name]: value,
    };
    this.setState({ resetFormData });

    let errorData = {
      ...this.state.errorData,
      [name]: null,
    };
    if (catelog.Strings.profile[name]) {
      const { valid, message } = await catelog.Strings.profile[name].validate(
        value
      );
      errorData[name] = valid ? null : message;
    }
    this.setState({ errorData });
  };
  handleChange = async (e) => {
    let catelog = Catalog;
    const { name, value } = e.target;
    const accountFormData = {
      ...this.state.accountFormData,
      [name]: value,
    };
    this.setState({ accountFormData });

    let errorData = {
      ...this.state.errorData,
      [name]: null,
    };
    if (catelog.Strings.profile[name]) {
      const { valid, message } = await catelog.Strings.profile[name].validate(
        value
      );
      errorData[name] = valid ? null : message;
    }
    this.setState({ errorData });
  };

  handleProfileChange = async (e) => {
    let catelog = Catalog;

    if(this.state.account&&this.state.account.role){
      switch (this.state.account.role.id) {
        case 5:
          catelog = Catalog.Lawyer
          break;
        case 6:
          catelog = Catalog.Firm
          break;
      
        default:
          break;
      }
    }
    const { name, value } = e.target;
    const profileFormData = {
      ...this.state.profileFormData,
      [name]: value,
    };
    this.setState({ profileFormData });

    let errorData = {
      ...this.state.errorData,
      [name]: null,
    };
    if (catelog.Strings.profile[name]) {
      const { valid, message } = await catelog.Strings.profile[name].validate(
        value
      );
      errorData[name] = valid ? null : message;
    }
    this.setState({ errorData });
  };

  handleFileChange = async (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      let formData = new FormData();
      formData.append("files", files[0]);
      let uploads = await SiteService.uploadMedia(formData);
      if (uploads.length > 0) {
        const profileFormData = {
          ...this.state.profileFormData,
          PrimaryImageId: uploads[0].id,
        };
        this.setState({ profileFormData });
      }
    }
  };

  handleContactChange =async (e) => {
    let catelog = Catalog;

    if(this.state.account&&this.state.account.role){
      switch (this.state.account.role.id) {
        case 5:
          catelog = Catalog.Lawyer
          break;
        case 6:
          catelog = Catalog.Firm
          break;
      
        default:
          break;
      }
    }
    const { name, value } = e.target;
    let profileFormData = this.state.profileFormData;
    profileFormData.contact[name] = value;
    this.setState({ profileFormData });

    let errorData = {
      ...this.state.errorData,
      [name]: null,
    };
    if(catelog.Strings.profile[name]){
      const {valid,message} =await catelog.Strings.profile[name].validate(value);
      errorData[name]=valid?null:message;
    }
    this.setState({ errorData });
  };
  handleProfileSubmit = async(e) => {
    e.preventDefault();
    e.stopPropagation();
    let accountFormData = this.state.accountFormData;
    let account = this.state.account;
    let profileFormData = this.state.profileFormData;
    let profile = this.state.profile;
    let errorData = {};
    let isInValid = false;
    let catelog=Catalog;
    for (const name in accountFormData) {
      if(catelog.Strings.profile[name]){
        const {valid,message} =await catelog.Strings.profile[name].validate(accountFormData[name]);      
        if (!valid) {
          errorData[name] = message;
          isInValid = true;
        }
      }
    }  
    let isSubmit = isInValid ? false : true;
    let error ,success= null;
    let profileForm = true;
    let readprofile=false;
    this.setState({ errorData, isSubmit,readprofile,profileForm,  error,success }); 
    if (!isInValid) {
      const id=this.state.account.id;
      if(this.state.account.role.id===4){

        const res1 = await SiteService.updateUser(accountFormData,id);
        if(res1.id){
            success = "Profile update successfully!";
            account=res1;
            accountFormData={first_name:res1.first_name,last_name:res1.last_name}
            errorData={};
            readprofile=true;
        }else{
          error = "Profile update Failed!";
        }
      }else{

        switch (this.state.account.role.id) {
          case 5:catelog = Catalog.Lawyer;
                break;
          case 6:catelog = Catalog.Firm;
                break;
          default:
                break;
        }
        profileFormData.SubCategory2=(this.state.account.role.id === 5)?0:1
        for (const name in profileFormData) {
          if (name === "contact") {
            for (const cname in profileFormData.contact) {
              if(catelog.Strings.profile[cname]){
                const {valid,message} =await catelog.Strings.profile[cname].validate(profileFormData.contact[cname]);   
                if(!valid){
                  errorData[cname] = message;
                  isInValid = true;
                }
              }                
            }
          } else {
            if(catelog.Strings.profile[name]){
              const {valid,message} =await catelog.Strings.profile[name].validate(profileFormData[name]);   
              if(!valid){
                errorData[name] = message;
                isInValid = true;
              }
            }
          }
        }
        isSubmit = isInValid ? false : true;
        this.setState({ errorData, isSubmit,readprofile,profileFormData,  error,success }); 
        if (!isInValid) {
            const res1 = await SiteService.updateUser(accountFormData,id);
            if(res1.id){
                // if (this.state.account.role.id === 5) { 
                    const res2 = await StoreService.updateLawyer(profileFormData,profile.id);
                    console.log(res2)
                    if(res2.id){
                      this.fetchLawyer()
                      success = "Profile update successfully!";
                      account=res1;
                      accountFormData={ first_name:res1.first_name, last_name:res1.last_name }
                      errorData={};
                      readprofile=true;
                    }else{ 
                      error = "Profile update Failed!";
                    }
                // }
                // if (this.state.account.role.id === 6) { 
                //     const res2 = await StoreService.updateFirm(profileFormData,profile.id);
                //     if(res2.id){   
                //       this.fetchFirm()
                //       success = "Profile update successfully!";
                //       account=res1;
                //       accountFormData={first_name:res1.first_name,last_name:res1.last_name}
                //       errorData={};
                //       readprofile=true;
                //     }else{
                //       profile = this.state.profile;
                //       error = "Profile update Failed!";
                //     }
                // }
            }else{
              error = "Profile update Failed!";
            }
        }
      }
    }
    isSubmit=false;
    console.log(errorData)
    this.setState({ errorData,account, isSubmit,readprofile,profileForm,accountFormData,  error,success });

  };

  handlePasswordSubmit =async (e) => {
    e.preventDefault();
    e.stopPropagation();

 
    let resetFormData = this.state.resetFormData;
    let errorData = {};
    let isInValid = false;
    
    let catelog=Catalog;
    for (const name in resetFormData) {
      if(catelog.Strings.profile[name]){
        const {valid,message} =await catelog.Strings.profile[name].validate(resetFormData[name]);      
        if (!valid) {
          errorData[name] = message;
          isInValid = true;
        }else if(name==="cpassword"){
          const {confirm,message}= catelog.Strings.profile['cpassword'].confirm(resetFormData['cpassword'],resetFormData['password'])
          if (!confirm) {
            errorData[name] = message;
            isInValid = true;
          }
        } 
      }
    }  
    let isSubmit = isInValid ? false : true;
    let error ,success= null;
    let profileForm = false;
    let readprofile=false;
    this.setState({ errorData, isSubmit,readprofile,  error });
    if (!isInValid) {
      const newform={ 
          currentPassword:resetFormData.currentPassword,
          password:resetFormData.password,
          passwordConfirmation:resetFormData.cpassword,
      }
      const res = await SiteService.changePassword(newform);  
      if (res.error) { 
        error = res.error.message;
      }else{
        success = "Password change successfully!";
        readprofile=true;
        resetFormData={
          currentPassword:null,
          password:null,
          cpassword:null,
        };
        errorData={};
        profileForm=true;
      }  
    }
    isSubmit=false;
    this.setState({ errorData,resetFormData,readprofile, isSubmit,  error,success ,profileForm});
  };

  render() {
    let catelog = Catalog;
    if(this.state.account&&this.state.account.role){
      switch (this.state.account.role.id) {
        case 5:
          catelog = Catalog.Lawyer
          break;
        case 6:
          catelog = Catalog.Firm
          break;
      
        default:
          break;
      }
    }
    return (
      <div className="account-wrap">
        {!Catalog.isLoad || Catalog.Session.isLogined ? (
          <Tab.Container id="account-tabs" defaultActiveKey="profile">
            <Row>
              {this.state.account.id && this.state.account.role.id === 4&&(<>
              
                <Col md={3}>
                  <Card>
                    <Card.Body>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="profile">Profile Detail</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link eventKey="billing">Billing Detail</Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                          <Nav.Link eventKey="order">My Order</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Body>
                  </Card>
                </Col> 
              </>)}
              <Col md={9}>
                <Card>
                  <Card.Body>
                  { this.state.error&&(<>
                  <Alert variant="danger" dismissible>
                    <p>{this.state.error}</p>
                  </Alert> 
                  </>)}
                 
                  { this.state.success&&(<>
                  <Alert variant="success" dismissible>
                    <p>{this.state.success}</p>
                  </Alert> 
                  </>)}
                    <Tab.Content>
                      <Tab.Pane eventKey="profile">
                        {this.state.profileForm ? (
                          <>
                            <Form
                              id={this.state.formProfileId}
                              className="profileForm"
                              onSubmit={this.handleProfileSubmit}
                              noValidate
                            >
                              <h3>Profile Details</h3>
                              <Row>
                                <Col md={6}>
                                  <FormField
                                    id={this.state.formProfileId + "firstName"}
                                    title={
                                      Catalog.Strings.profile.first_name.title
                                    }
                                    placeholder={
                                      Catalog.Strings.profile.first_name
                                        .placeholder
                                    }
                                    value={
                                      this.state.accountFormData?.first_name
                                    }
                                    error={this.state.errorData?.first_name}
                                    name={"first_name"}
                                    handle={this.handleChange}
                                    readOnly={this.state.readprofile}
                                  />
                                </Col>
                                <Col md={6}>
                                  <FormField
                                    id={this.state.formProfileId + "lastName"}
                                    title={
                                      Catalog.Strings.profile.last_name.title
                                    }
                                    placeholder={
                                      Catalog.Strings.profile.last_name
                                        .placeholder
                                    }
                                    value={
                                      this.state.accountFormData?.last_name
                                    }
                                    error={this.state.errorData?.last_name}
                                    name={"last_name"}
                                    handle={this.handleChange}
                                    readOnly={this.state.readprofile}
                                  />
                                </Col>
                                <Col md={6}>
                                  <FormField
                                    id={this.state.formProfileId + "email"}
                                    title={Catalog.Strings.profile.email.title}
                                    placeholder={
                                      Catalog.Strings.profile.email.placeholder
                                    }
                                    value={this.state.account?.email}
                                    readOnly={true}
                                  />
                                </Col>
                                <Col md={6}>
                                  <FormField
                                    id={this.state.formProfileId + "mobile"}
                                    title={Catalog.Strings.profile.mobile.title}
                                    placeholder={
                                      Catalog.Strings.profile.mobile.placeholder
                                    }
                                    value={this.state.account?.mobile}
                                    readOnly={true}
                                  />
                                </Col>
                              </Row>
                              {this.state.account.id &&
                                (this.state.account.role.id === 5 ||
                                  this.state.account.role.id === 6) && (
                                  <>
                                    <h6>Personal Details</h6>
                                    <Row>
                                      
                                      {this.state.readprofile?(<Col md={12}>
                                      <div className="avathar">
                                        <img src={this.state.profile.image} alt="avathar" />
                                      </div>
                                      
                                      </Col>):( <Col md={6}>
                                        <FormFile
                                          id={this.state.formProfileId + "image"}
                                          title={
                                            catelog.Strings.profile.PrimaryImageId.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.PrimaryImageId
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.PrimaryImageId
                                          }
                                          error={this.state.errorData?.PrimaryImageId}
                                          name={"PrimaryImageId"}
                                          handle={this.handleFileChange}
                                          readOnly={this.state.readprofile}
                                        /> 
                                     </Col>)}
                                         
                                      
                                      <Col md={6}>
                                        <FormField
                                          id={this.state.formProfileId + "title"}
                                          title={
                                            catelog.Strings.profile.Title.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.Title
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.Title
                                          }
                                          error={this.state.errorData?.Title}
                                          name={"Title"}
                                          handle={this.handleProfileChange}
                                          readOnly={this.state.readprofile}
                                        />  
                                      </Col>
                                       
                                      <Col md={6}>
                                        <FormField
                                          id={this.state.formProfileId + "Identifier"}
                                          title={
                                            catelog.Strings.profile.Identifier.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.Identifier
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.Identifier
                                          }
                                          error={this.state.errorData?.Identifier}
                                          name={"Identifier"}
                                          handle={this.handleProfileChange}
                                          readOnly={this.state.readprofile}
                                        />  
                                      </Col>
                                      <Col md={6}>
                                      {this.state.readprofile?(<>
                                      
                                        <FormField
                                          id={this.state.formProfileId + "DisplayDate_show"}
                                          title={
                                            catelog.Strings.profile.DisplayDate.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.DisplayDate
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.DisplayDate
                                          } 
                                          readOnly={true}
                                        />  
                                      
                                      </>):(<>
                                      
                                        <FormDatePicker
                                          id={this.state.formProfileId + "DisplayDate"}
                                          title={
                                            catelog.Strings.profile.DisplayDate.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.DisplayDate
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.DisplayDate
                                          }
                                          error={this.state.errorData?.DisplayDate}
                                          name={"DisplayDate"}
                                          handle={this.handleProfileChange} 
                                        />  
                                      
                                      </>)}
                                      </Col>
                                      <Col md={6}>
                                        <FormField
                                          id={this.state.formProfileId + "SubTitle"}
                                          title={
                                            catelog.Strings.profile.SubTitle.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.SubTitle
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.SubTitle
                                          }
                                          error={this.state.errorData?.SubTitle}
                                          name={"SubTitle"}
                                          handle={this.handleProfileChange}
                                          readOnly={this.state.readprofile}
                                        />  
                                      </Col>
                                      <Col md={6}>                                      
                                        <FormField
                                          id={this.state.formProfileId + "email_address"}
                                          title={
                                            catelog.Strings.profile.email_address.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.email_address
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.contact?.email_address
                                          }
                                          error={this.state.errorData?.email_address}
                                          name={"email_address"}
                                          handle={this.handleContactChange}
                                          readOnly={this.state.readprofile}
                                        />  
                                      </Col>
                                      <Col md={6}>                                      
                                        <FormField
                                          id={this.state.formProfileId + "phone_number"}
                                          title={
                                            catelog.Strings.profile.phone_number.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.phone_number
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.contact?.phone_number
                                          }
                                          error={this.state.errorData?.phone_number}
                                          name={"phone_number"}
                                          handle={this.handleContactChange}
                                          readOnly={this.state.readprofile}
                                        />  
                                      </Col>
                                      <Col md={6}>                                      
                                        <FormField
                                          id={this.state.formProfileId + "address"}
                                          title={
                                            catelog.Strings.profile.address.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.address
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.contact?.address
                                          }
                                          error={this.state.errorData?.address}
                                          name={"address"}
                                          handle={this.handleContactChange}
                                          readOnly={this.state.readprofile}
                                        />  
                                      </Col>

                                      <Col md={6}>                                      
                                        <FormField
                                          id={this.state.formProfileId + "website_link"}
                                          title={
                                            catelog.Strings.profile.website_link.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.website_link
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.contact?.website_link
                                          }
                                          error={this.state.errorData?.website_link}
                                          name={"website_link"}
                                          handle={this.handleContactChange}
                                          readOnly={this.state.readprofile}
                                        />  
                                      </Col>


                                      <Col md={6}>       
                                         
                                      {this.state.readprofile?( <div className="form-group"> 
                                        <label>{catelog.Strings.profile.Property1.title}</label>
                                        <div className="form-control">
                                      {
                                        (this.state.profileFormData?.Property1??[]).map((i)=> <Badge bg="dark">{i}</Badge> )
                                      }</div>
                                       </div>):(<>
                                        <FormDropdown
                                        id={this.state.formProfileId + "state"}
                                        title={catelog.Strings.profile.Property1.title}
                                        placeholder={catelog.Strings.profile.Property1.placeholder} 
                                        value={this.state.profileFormData?.Property1}
                                        error={this.state.errorData?.Property1}
                                        options={catelog.Config.Property1}
                                        name={"Property1"}
                                        handle={this.handleProfileChange}
                                        multiple={true}
                                      />
                                       </>)}  
                                      </Col>

                                      <Col md={6}>       
                                         
                                      {this.state.readprofile?( <div className="form-group"> 
                                        <label>{catelog.Strings.profile.Property2.title}</label>
                                        <div className="form-control">
                                      {
                                        (this.state.profileFormData?.Property2??[]).map((i)=> <Badge bg="dark">{i}</Badge> )
                                      }</div>
                                       </div>):(<>
                                        <FormDropdown
                                        id={this.state.formProfileId + "city"}
                                        title={catelog.Strings.profile.Property2.title}
                                        placeholder={catelog.Strings.profile.Property2.placeholder} 
                                        value={this.state.profileFormData?.Property2}
                                        error={this.state.errorData?.Property2}
                                        options={catelog.Config.Property2}
                                        name={"Property2"}
                                        handle={this.handleProfileChange}
                                        multiple={true}
                                      />
                                       </>)}  
                                      </Col>

                                      <Col md={6}>       
                                         
                                      {this.state.readprofile?( <div className="form-group"> 
                                        <label>{catelog.Strings.profile.PropertyList1.title}</label>
                                        <div className="form-control">
                                      {
                                        (this.state.profileFormData?.PropertyList1??[]).map((i)=> <Badge bg="dark">{i}</Badge> )
                                      }</div>
                                       </div>):(<>
                                        <FormDropdown
                                        id={this.state.formProfileId + "specializationareas"}
                                        title={catelog.Strings.profile.PropertyList1.title}
                                        placeholder={catelog.Strings.profile.PropertyList1.placeholder} 
                                        value={this.state.profileFormData?.PropertyList1}
                                        error={this.state.errorData?.PropertyList1}
                                        options={catelog.Config.PropertyList1}
                                        name={"PropertyList1"}
                                        handle={this.handleProfileChange}
                                        multiple={true}
                                      />
                                       </>)}  
                                      </Col>

                                      <Col md={6}>       
                                         
                                      {this.state.readprofile?( <div className="form-group"> 
                                        <label>{catelog.Strings.profile.PropertyList2.title}</label>
                                        <div className="form-control">
                                      {
                                        (this.state.profileFormData?.PropertyList2??[]).map((i)=> <Badge bg="dark">{i}</Badge> )
                                      }</div>
                                       </div>):(<>
                                        <FormDropdown
                                        id={this.state.formProfileId + "courts"}
                                        title={catelog.Strings.profile.PropertyList2.title}
                                        placeholder={catelog.Strings.profile.PropertyList2.placeholder} 
                                        value={this.state.profileFormData?.PropertyList2}
                                        error={this.state.errorData?.PropertyList2}
                                        options={catelog.Config.PropertyList2}
                                        name={"PropertyList2"}
                                        handle={this.handleProfileChange}
                                        multiple={true}
                                      />
                                       </>)}  
                                      </Col>

                                      <Col md={6}>       
                                         
                                      {this.state.readprofile?( <div className="form-group"> 
                                        <label>{catelog.Strings.profile.PropertyList3.title}</label>
                                        <div className="form-control">
                                      {
                                        (this.state.profileFormData?.PropertyList3??[]).map((i)=> <Badge bg="dark">{i}</Badge> )
                                      }</div>
                                       </div>):(<>
                                        <FormDropdown
                                        id={this.state.formProfileId + "propertylist3"}
                                        title={catelog.Strings.profile.PropertyList3.title}
                                        placeholder={catelog.Strings.profile.PropertyList3.placeholder} 
                                        value={this.state.profileFormData?.PropertyList3}
                                        error={this.state.errorData?.PropertyList3}
                                        options={catelog.Config.PropertyList3}
                                        name={"PropertyList3"}
                                        handle={this.handleProfileChange}
                                        multiple={true}
                                      />
                                       </>)}  
                                      </Col> 
                                      <Col md={6}>
                                        <FormField
                                          id={this.state.formProfileId + "Price"}
                                          title={
                                            catelog.Strings.profile.Price.title
                                          }
                                          placeholder={
                                            catelog.Strings.profile.Price
                                              .placeholder
                                          }
                                          value={
                                            this.state.profileFormData?.Price
                                          }
                                          error={this.state.errorData?.Price}
                                          name={"Price"}
                                          handle={this.handleProfileChange}
                                          readOnly={this.state.readprofile}
                                        />  
                                        </Col> 
                                        <Col md={12}>
                                        <FormTextArea
                                          id={this.state.formProfileId + "profiledetail"}
                                          title={catelog.Strings.profile.Detail1.title}
                                          placeholder={catelog.Strings.profile.Detail1.placeholder}
                                          value={this.state.profileFormData?.Detail1}
                                          error={this.state.errorData?.Detail1}
                                          name={"Detail1"}
                                          handle={this.handleProfileChange}
                                          readOnly={this.state.readprofile}
                                        />
                                        </Col> 

                                    </Row>
                                  </>
                                )}
                              {!this.state.readprofile && (
                                <>
                                  <div className="form-action">
                                    <Button
                                      variant="secondary"
                                      type="button"
                                      disabled={this.state.isSubmit}
                                      onClick={(e) => {
                                        console.log(this.state.profileFormData)
                                        e.preventDefault();
                                        const readprofile = true;
                                        const accountFormData = {first_name:this.state.account.first_name,last_name:this.state.account.last_name};
                                        const {PrimaryImageId,image,Category,...FormData}=this.state.profile;
                                        const profileFormData ={
                                          ...FormData,
                                          PrimaryImageId:PrimaryImageId?.data?.id??null
                                        }
                                        const errorData = {};
                                         
                                        this.setState({
                                          readprofile,
                                          accountFormData,
                                          profileFormData,
                                          errorData
                                        });
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      disabled={this.state.isSubmit}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </>
                              )}
                            </Form>
                          </>
                        ) : (
                          <>
                            <Form
                              id={this.state.formLoginId}
                              className="profileForm"
                              onSubmit={this.handlePasswordSubmit}
                              noValidate
                            >
                            <FormField
                              id={this.state.formLoginId + "currentPassword"}
                              title={Catalog.Strings.profile.currentPassword.title}
                              placeholder={
                                Catalog.Strings.profile.currentPassword.placeholder
                              }
                              value={this.state.resetFormData?.currentPassword}
                              error={this.state.errorData?.currentPassword}
                              name={"currentPassword"}
                              handle={this.handlePasswordChange}
                              type="password"
                            />
                              <FormField
                                id={this.state.formLoginId + "password"}
                                title={Catalog.Strings.profile.password.title}
                                placeholder={
                                  Catalog.Strings.profile.password.placeholder
                                }
                                value={this.state.resetFormData?.password}
                                error={this.state.errorData?.password}
                                name={"password"}
                                handle={this.handlePasswordChange}
                                type="password"
                              />
                              <FormField
                                id={this.state.formId + "cpassword"}
                                title={Catalog.Strings.profile.cpassword.title}
                                placeholder={
                                  Catalog.Strings.profile.cpassword.placeholder
                                }
                                value={this.state.resetFormData?.cpassword}
                                error={this.state.errorData?.cpassword}
                                name={"cpassword"}
                                handle={this.handlePasswordChange}
                                type="password"
                              />
                              <div className="form-action">
                                <Button
                                  variant="secondary"
                                  type="button"
                                  disabled={this.state.isSubmit}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const readprofile = true;
                                    const profileForm = true;

                                  const errorData = {};
                                    this.setState({ readprofile, profileForm,errorData });
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled={this.state.isSubmit}
                                >
                                  Change Password
                                </Button>
                              </div>
                            </Form>
                          </>
                        )}
                        {this.state.readprofile && (
                          <>
                            <div className="form-links">
                              <Link
                                onClick={(e) => {
                                  e.preventDefault();
                                  const readprofile = false;
                                  const accountFormData = {first_name:this.state.account.first_name,last_name:this.state.account.last_name};
                                  const {PrimaryImageId,image,Category,...FormData}=this.state.profile;
                                  const profileFormData ={
                                    ...FormData,
                                    PrimaryImageId:PrimaryImageId?.data?.id??null
                                  } 
                                  const errorData = {};
                                  this.setState({
                                    readprofile,
                                    accountFormData,
                                    profileFormData,
                                    errorData
                                  });
                                }}
                              >
                                Edit Profile Detail
                              </Link>
                              <br />
                              <Link
                                onClick={(e) => {
                                  e.preventDefault();
                                  const readprofile = false;
                                  const profileForm = false;
                                  const resetFormData = {
                                    currentPassword:null,
                                    password:null,
                                    cpassword:null,
                                  };
                                  const errorData = {};
                                  this.setState({ readprofile, profileForm,resetFormData ,errorData});
                                }}
                              >
                                Change Password
                              </Link>
                            </div>
                          </>
                        )}
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="billing"></Tab.Pane> */}
                      <Tab.Pane eventKey="order">

                        <h4>My Orders ({this.state.orders.pagination.total})</h4>
 
                           {this.state.orders.data.map((order,index)=>{
                            return <Card>
                              <Card.Body>  
                                <div className="order-item">
                                  <div className="order-item-avathar">
                                    <img src={order.item_detail.image} alt={order.item_detail.Title||order.item_detail.title||""} />
                                  </div>
                                  <div className="order-item-title">{order.item_detail.Title||order.item_detail.title||""}</div>
                                  <div className="order-item-action">
                                      <Link to={`/catalogs/books/${order.item_detail.id}/${titleSlug(order.item_detail.Title||order.item_detail.title||"")}`}>view details</Link>
                                  </div>                                  
                                </div> 
                                </Card.Body>
                            </Card>
                           })} 
                      </Tab.Pane>
                    </Tab.Content>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab.Container>
        ) : (
          <>
            <Navigate replace to="/login" />
          </>
        )}
      </div>
    );
  }
}
export default AccountController;
