import "./../css/ResetPassword.css";
import ResetPasswordController from "../controllers/ResetPasswordController";
import { useParams, useSearchParams } from "react-router-dom";

const ResetPassword = () => { 
    window.scrollTo(0, 0); 
    const params = useParams();
    const [searchParams] = useSearchParams();
    return ( <ResetPasswordController  {...params} code={searchParams.get('code')} ></ResetPasswordController>  );
};
  
export default ResetPassword;
  